<div class="container" *ngIf="errorTitle">
	<div class="alert alert-info" role="alert">
		<div class="row">
			<div class="col-xs-3">
				<i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
			</div>
			<div class="col-xs-9">
				<h1>{{errorTitle}}</h1>
				<h2>{{errorMessage}}</h2>

				<a class="mail" href="mailto:opendata@aragon.es">
					<h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
				</a>

			</div>
		</div>
	</div>
</div>
<div class="container" *ngIf="!errorTitle">
	<div class="dtHeader row">
		<div class="globaltitle col-12 col-md-10">
			<h1 tabindex="{{openedMenu ? -1 : 0}}">{{org.title}}</h1>
			<button tabindex="{{openedMenu ? -1 : 0}}" *ngIf="showEdit" class="tags" icon="fas fa-pencil-alt" pButton type="button" label="Editar" style="margin-bottom: 10px;" routerLink="/login/org/{{org.name}}"></button>
		</div>
		<div class="social col-12 col-md-2">
			<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkFacebookShare}}" target="_blank" title="Compartir en Facebook">
				<img src="{{assetsUrl}}/public/contenido-general/social/facebookIcon.png" alt="compartir-en-Facebook">
			</a>
			<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkTwitterShare}}" target="_blank" title="Compartir en Twitter">
				<img src="{{assetsUrl}}/public/contenido-general/social/twitterIcon.png" alt="compartir-en-Twitter">
			</a>
		</div>
	</div>
<div class="ui-g details">
	<div class="ui-g-12">
		<h6 tabindex="{{openedMenu ? -1 : 0}}"><b>NOMBRE DE LA ORGANIZACIÓN:</b></h6>
		<p tabindex="{{openedMenu ? -1 : 0}}">{{org.title}}</p>
	</div>		
	<div class="ui-g-12">
		<h6 tabindex="{{openedMenu ? -1 : 0}}"><b>PÁGINA WEB DE LA ORGANIZACIÓN:</b></h6>
		<div *ngIf="webpage; else NOT_AVAILABLE">
			<p><a tabindex="{{openedMenu ? -1 : 0}}" href="{{webpage}}">{{webpage}}</a></p>
		</div>
		<div tabindex="{{openedMenu ? -1 : 0}}" *ngIf="!webpage">
			<p tabindex="{{openedMenu ? -1 : 0}}">No disponible</p>
		</div>
	</div>		
	<div class="ui-g-12">
		<h6 tabindex="{{openedMenu ? -1 : 0}}"><b>DESCRIPCIÓN DE LA ORGANIZACIÓN:</b></h6>
		<p tabindex="{{openedMenu ? -1 : 0}}">{{org.description || "No disponible"}}</p>
	</div>		
	<div class="ui-g-12">
		<h6 tabindex="{{openedMenu ? -1 : 0}}"><b>DIRECCIÓN DE LA ORGANIZACIÓN:</b></h6>
		<p tabindex="{{openedMenu ? -1 : 0}}">{{address || "No disponible"}}</p>
	</div>		
	<div class="ui-g-12">
		<h6 tabindex="{{openedMenu ? -1 : 0}}"><b>PERSONA RESPONSABLE DENTRO DE LA ORGANIZACIÓN:</b></h6>
		<p tabindex="{{openedMenu ? -1 : 0}}">{{person || "No disponible"}}</p>
	</div>		
	<div class="ui-g-12">
		<h6 tabindex="{{openedMenu ? -1 : 0}}"><b>EMAIL DE CONTACTO:</b></h6>
		<p tabindex="{{openedMenu ? -1 : 0}}">{{email || "No disponible"}}</p>
	</div>
	<div class="ui-g-12"></div>
</div>
<p-dataTable [value]="datasets" (onSort)="setOrder($event)"#dt>
	<p-column field="name" header="{{numDatasets}} conjuntos de datos encontrados" sortable="custom" sortFunction="setOrder($event)">
		<ng-template pTemplate="body" let-dataset="rowData">
			<a tabindex="{{openedMenu ? -1 : 0}}" routerLink='{{routerLinkDataCatalogDataset}}/{{dataset.name}}' (click)="showDataset(dataset)">
				  {{dataset.title}}
			  </a>
		</ng-template>
	</p-column>
	<p-column field="accesos" header="Nº de accesos" [style]="{'width':'160px'}" sortable="custom" sortFunction="setOrder($event)">
		<ng-template pTemplate="body"  let-dataset="rowData">
			{{dataset?.tracking_summary?.total || '0'}}
		</ng-template>
	</p-column>
	<p-column field="lastUpdate" header="Última actualización" [style]="{'width':'220px'}" sortable="custom" sortFunction="setOrder($event)"  (window:resize)="onResize()" [hidden]="hideLastUpdateColumn">
		<ng-template pTemplate="body" let-dataset="rowData">
			{{dataset.metadata_modified | date: 'dd/MM/yyyy'}}
		</ng-template>
	</p-column>
</p-dataTable>
<nav aria-label="Pagination">
		<ul class="pagination justify-content-center">
			<li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
				<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+pageFirst+1)" aria-label="Primero">
				<i class="fas fa-fast-backward" ></i>
				</a>
			</li>
			<li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
				<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage)" aria-label="Anterior">
				<i class="fas fa-step-backward"></i>
				</a>
			</li>
			<li *ngFor="let pageShow of pagesShow" [ngClass]="pageShow == '-' ? 'page-item disabled' : (pageShow == (+actualPage+1) ? 'page-item active': 'page-item')">
				<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageShow)">{{pageShow}}</a>
			</li>

			<li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
				<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage+2)" aria-label="Siguiente">
					<i class="fas fa-step-forward"></i>
					</a>
			</li>
			<li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
				<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageLast)" aria-label="Ultimo">
					<i class="fas fa-fast-forward"></i>
					</a>
			</li>
		</ul>
	</nav>

<div class="nota">
	<p>
		<i class="fas fa-star" id="informacion_content_3_i" style="color: #5ea2ba;"></i>
		Se ha accedido al conjunto de datos más de {{ocurrences}} veces en los últimos {{days}} días.
	</p>
</div>
</div>