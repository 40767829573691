<div class="container">
	<p-messages closable="true" label="Administración de portales Kibana" [(value)]="userAdminMessages"></p-messages>
	<p-growl sticky="true" life="2000" [(value)]="userAdminMessages"></p-growl>

	<p-dataTable [loading]="false" [value]="portals" [responsive]="true" emptyMessage="No se han encontrado registros."
		[rows]="5" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
		<p-column styleClass="text-center" field="portal_name" [style]="{'width':'15%'}" header="Portal"></p-column>
		<p-column styleClass="text-center" field="type" [style]="{'width':'10%'}" header="Tipo"></p-column>
		<p-column styleClass="text-center" field="view" [style]="{'width':'10%'}" header="Vista"></p-column>
		<p-column styleClass="text-center" field="delay" [style]="{'width':'10%'}" header="Delay"></p-column>
		<p-column styleClass="text-center" field="status" [style]="{'width':'10%'}" header="Estado">
			<ng-template pTemplate="body" let-logstash="rowData">
				<div *ngIf="logstash.status=='0'" class="circle-red"></div>
				<div *ngIf="logstash.status=='1'" class="circle-green"></div>
			</ng-template>
		</p-column>
		<p-column styleClass="text-center" field="url" [style]="{'width':'37%'}" header="Url"></p-column>
		<p-column styleClass="text-center" header="Edición" [style]="{'width':'15%'}">
			<ng-template pTemplate="body" let-logstash="rowData">
				<button pButton type="button" icon="fas fa-edit"
					(click)="showDialog(logstash, true, false)"></button>
				<button pButton type="button" icon="fas fa-arrow-up" *ngIf="logstash.status=='0'"
					(click)="enableLogstash(logstash.id_logstash)"></button>
				<button pButton type="button" icon="fas fa-arrow-down" *ngIf="logstash.status=='1'"
					(click)="disableLogstash(logstash.id_logstash)"></button>
				<button pButton type="button" icon="fas fa-calendar" (click)="showReloadDialog(logstash)"></button>
				<button pButton type="button" icon="fas fa-trash-alt" (click)="showDeleteDialog(logstash)"></button>
			</ng-template>
		</p-column>
		<p-footer>
			<div class="ui-helper-clearfix" style="width:100%">
				<button type="button" pButton icon="fas fa-plus" style="float:left"
					(click)="showDialog(null, false, true)" label="Nuevo portal"></button>
			</div>
		</p-footer>
	</p-dataTable>
	<p-dialog header="¿Está seguro de que desea borrar el portal?" [(visible)]="displayDeleteDialog">
		<p>Se va a proceder a borrar el portal
			<b>{{logstash?.portal_name}}</b>
		</p>
		<p>¿Desea continuar?</p>
		<p-footer>
			<button type="button" pButton icon="fas fa-check" label="Sí" (click)="deleteLogstash()"></button>
			<button type="button" pButton icon="fas fa-times" label="No" (click)="undoDeleteLogstash()"></button>
		</p-footer>
	</p-dialog>
	<p-dialog appendTo="body" header="{{editDialogTitle}}" [(visible)]="displayEditDialog" [responsive]="true"
		showEffect="fade" modal="true">
		<div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="logstash">
			<div class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3">
					<label for="portal_name">Portal:</label>
				</div>
				<div class="ui-grid-col-9">
					<input pInputText id="portal_name" [(ngModel)]="logstash.portal_name" />
				</div>
			</div>
			<div class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3">
					<label for="type">Tipo:</label>
				</div>
				<div class="ui-grid-col-9">
					<select class="form-control" name="singleSelect" id="singleSelect" [(ngModel)]="logstash.type">
						<option value="urchin">Urchin</option>
						<option value="analytics">Google Analytics</option>
					</select>
				</div>
			</div>
			<div class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3">
					<label for="view">Vista:</label>
				</div>
				<div class="ui-grid-col-9">
					<input pInputText id="view" [(ngModel)]="logstash.view" />
				</div>
			</div>
			<div class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3">
					<label for="delay">Delay:</label>
				</div>
				<div class="ui-grid-col-9">
					<input pInputText id="delay" [(ngModel)]="logstash.delay" />
				</div>
			</div>
			<div class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3">
					<label for="url">URL (Sin http://):</label>
				</div>
				<div class="ui-grid-col-9">
					<input pInputText id="url" [(ngModel)]="logstash.url" />
				</div>
			</div>
			<p-footer>
				<div *ngIf="editing" class="ui-dialog-buttonpane ui-helper-clearfix">
					<button type="button" pButton icon="fas fa-check" label="Guardar"
						(click)="updateLogstash()"></button>
				</div>
				<div *ngIf="!editing" class="ui-dialog-buttonpane ui-helper-clearfix">
					<button type="button" pButton icon="fas fa-check" label="Crear Portal"
						(click)="saveLogstash()"></button>
				</div>
			</p-footer>
		</div>
	</p-dialog>

	<p-dialog header="Recarga de datos del portal" [(visible)]="displayReloadDialog">
		<div class="ui-grid ui-grid-responsive ui-fluid">
			<div class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3">
					<label for="portal_name">Desde:</label>
				</div>
				<div class="ui-grid-col-9">
					<input pInputText type="date" id="fromdate" [(ngModel)]="fromDate" />
				</div>
			</div>
			<div class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3">
					<label for="portal_name">Hasta:</label>
				</div>
				<div class="ui-grid-col-9">
					<input pInputText type="date" id="todate" [(ngModel)]="toDate" />
				</div>
			</div>

		</div>
		<p-footer>
			<div class="ui-dialog-buttonpane ui-helper-clearfix">
				<button type="button" pButton icon="fas fa-check" [disabled]="!fromDate || !toDate" label="Recargar"
					(click)="reloadLogstash()" style="
					width: 100%;"></button>
			</div>
		</p-footer>
	</p-dialog>
</div>
