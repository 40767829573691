<nav class="navbar navbar-expand-md navbar-light bg-faded">
	<a class="navbar-brand" routerLink="/" routerLinkActive="active">
    	<img class="navbar-brand" src="{{assetsUrl}}/public/header/images/AOD-Logo.png"  class="d-inline-block align-top"  alt="" height="50" width="260" alt="logo-aragón-open-data">
  	</a>
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar6">
		<span class="navbar-toggler-icon"></span>
	</button>
	<div class="navbar-collapse collapse justify-content-stretch" id="navbar6">
		<ul class="navbar-nav">
			<li class="nav-item active">
				<a class="nav-link" routerLink="/admin/global" routerLinkActive="">Inicio<span class="sr-only">(current)</span></a>
			</li>
			<li *ngIf="showUsersMenu" class="nav-item dropdown">
				<a tabindex="0" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Administración de Usuarios
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" routerLink="./global/users" >
						<span class="sidebar-title">Usuarios</span>
					</a>
				</div>
			</li>

			<li *ngIf="showSysAdminMenu" class="nav-item active">
				<a class="nav-link" routerLink="./global/sanitycheck">Sanity Check<span class="sr-only">(current)</span></a>
			</li>

			<li *ngIf="!showSysAdminMenu" class="nav-item dropdown">
				<a tabindex="0" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Banco de Datos
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" routerLink="/admin/datacenter/datasets" >
						<span class="sidebar-title">Datasets</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/datacenter/organizations/list" >
						<span class="sidebar-title">Organizaciones</span>
					</a>
				</div>
			</li>

			<li *ngIf="showAnalyticsMenu" class="nav-item dropdown">
				<a tabindex="0" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Analytics
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" routerLink="/admin/logstash" >
						<span class="sidebar-title">Portales</span>
					</a>
				</div>
			</li>
			<li *ngIf="showContentEditionMenu" class="nav-item dropdown">
				<a tabindex="0" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Contenido estático
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" routerLink="/admin/global/content/panels/open-data" (click)="navigate('open-data')" routerLinkActive="active">
						<span class="sidebar-title">Información</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/global/content/panels/conocimiento" (click)="navigate('conocimiento')" routerLinkActive="active">
						<span class="sidebar-title">Conocimiento</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/global/content/lists/applications" (click)="navigate('applications')" routerLinkActive="active">
						<span class="sidebar-title">Aplicaciones</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/global/content/panels/events" (click)="navigate('events')" routerLinkActive="active">
						<span class="sidebar-title">Eventos</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/global/content/panels/developers" (click)="navigate('developers')" routerLinkActive="active">
						  <span class="sidebar-title">Desarrolladores</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/global/content/panels/apis" (click)="navigate('apis')" routerLinkActive="active">
						  <span class="sidebar-title">APIs</span>
					</a>
				</div>
			</li>
			<li *ngIf="showContentEditionMenu" class="nav-item dropdown">
				<a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">AOD Campus<span class="sr-only"></span></a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" routerLink="/admin/campus-admin/entries" routerLinkActive="active">
						<span class="sidebar-title">Entradas</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/campus-admin/events" routerLinkActive="active">
						<span class="sidebar-title">Eventos</span>
					</a>
					<a class="dropdown-item" routerLink="/admin/campus-admin/speakers" routerLinkActive="active">
						<span class="sidebar-title">Ponentes</span>
					</a>
				</div>
			</li>
			<li *ngIf="showVisualData">
				<a class="nav-link" routerLink="/admin/visual-data">Visual Data</a>
			</li>
			<li *ngIf="!showSysAdminMenu" class="nav-item dropdown">
				<a tabindex="0" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Open Data Focus
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" routerLink="/admin/focus/histories" >
						<span class="sidebar-title">Historias</span>
					</a>
				</div>
			</li>
		</ul>
		<ul class="navbar-nav ml-auto">
			<li class="nav-item dropdown">
				<a tabindex="0" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
					<i class="fas fa-user"></i>
					{{currentUserName}}
				</a>
				<div class="dropdown-menu dropdown-menu-right" aria-labelledby="Preview">
					<a tabindex="0" class="dropdown-item" (click)="logout()"><i class="fas fa-sign-out-alt" aria-hidden="true"></i>Cerrar Sesión</a>
				</div>
			</li>
		</ul>
	</div>
</nav>
<router-outlet></router-outlet>