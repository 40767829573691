<!-- <div class="container" id="wrapper">
	<div #sidenav mode="side" opened="true" id="sidebar-wrapper">
		<ul id="sidemenu" class="sidebar-nav">
			<li>
				<a routerLink="./" routerLinkActive="active">
          			<span class="sidebar-title">Home</span>
        		</a>
			</li>
			<li>
				<a routerLink="./users" routerLinkActive="active">
					<span class="sidebar-title">Usuarios</span>
				</a>
			</li>
			<li>
				<a routerLink="./roles" routerLinkActive="active">
					<span class="sidebar-title">Roles</span>
				</a>
			</li>
			<li>
				<a class="accordion-toggle collapsed toggle-switch" data-toggle="collapse" href="#submenu-2">
					<span class="sidebar-title">Contenido</span>
					<i class="fas fa-caret-down"></i>
					<b class="caret"></b>
				</a>
				<ul id="submenu-2" class="panel-collapse collapse panel-switch" role="menu">
					<li>
						<a routerLink="./content/info" routerLinkActive="active"><i class="fas fa-caret-right"></i>
              				<span class="sidebar-title">Información</span>
            			</a>
					</li>
					<li>
						<a routerLink="./content/apps" routerLinkActive="active"><i class="fas fa-caret-right"></i>
              				<span class="sidebar-title">Aplicaciones</span>
            			</a>
					</li>
					<li>
						<a routerLink="./content/events" routerLinkActive="active"><i class="fas fa-caret-right"></i>
							<span class="sidebar-title">Eventos</span>
						</a>
					</li>
					<li>
						<a routerLink="./content/collaboration" routerLinkActive="active"><i class="fas fa-caret-right"></i>
							<span class="sidebar-title">Colabora</span>
						</a>
					</li>
					<li>
						<a routerLink="./content/developers" routerLinkActive="active"><i class="fas fa-caret-right"></i>
              				<span class="sidebar-title">Desarrolladores</span>
            			</a>
					</li>
					<li>
						<a routerLink="./content/apis" routerLinkActive="active"><i class="fas fa-caret-right"></i>
              				<span class="sidebar-title">APIs</span>
            			</a>
					</li>
					<li>
						<a routerLink="./content/sparql" routerLinkActive="active"><i class="fas fa-caret-right"></i>
              				<span class="sidebar-title">SPARQL</span>
            			</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
	<button md-mini-fab class="example-fab" (click)="sidenav.toggle()">
    	<i class="material-icons">menu</i>
  	</button>
	<router-outlet></router-outlet>
<div> -->
<router-outlet></router-outlet>