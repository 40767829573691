<div class="container">

    <!-- Dialogs -->
    <p-dialog header="¿Está seguro de que deasea borrar la historia?" [(visible)]="displayDeleteDialog">
        <p>Se va a proceder a borrar la historia <b> </b></p>
        <p>¿Desea continuar?</p>
        <p-footer>
            <button type="button" pButton icon="fas fa-times" (click)="cancelDeleteHistory()" label="No"></button>
            <button type="button" pButton icon="fas fa-check" (click)="deleteHistory()" label="Sí"></button>
        </p-footer>
    </p-dialog>

    <!-- Dialogs -->
    <p-dialog header="¿Está seguro de que deasea realizar esta acción?" [(visible)]="displayActionDialog">
        <p>Se va a proceder al cambio de estado <b> </b></p>
        <p>¿Desea continuar?</p>
        <p-footer>
            <button type="button" pButton icon="fas fa-times" (click)="cancelAction()" label="No"></button>
            <button type="button" pButton icon="fas fa-check" (click)="doAction()" label="Sí"></button>
        </p-footer>
    </p-dialog>


    <div class="row">
        <div class="col-12" >
            <div class="form-group">
                <label>Buscar</label>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Buscar por..." (keyup.enter)="searchHistoriesByText(searchInput.value)" #searchInput>
                        <span class="input-group-btn">
                            <button class="btn btn-secondary" type="button" (click)="searchHistoriesByText(searchInput.value)" ><i class="fas fa-search" aria-hidden="true"></i></button>
                        </span>
                    </div>                
            </div>
        </div>
    </div>
    
    <div *ngIf="showProgressBar" class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
    </div>

    <p-dataTable *ngIf="histories" [value]="histories" (onSort)="setOrder($event)" [responsive]="true" [loading]="showProgressBar" #dt>

        <p-column field="title" [style]="{'width':'30%'}" sortable="custom" sortFunction="orderTable($event)" header="{{numHistories}} conjuntos de datos encontrados">
            <ng-template pTemplate="body" let-history="rowData">
                {{history.title}}
            </ng-template>
        </p-column>

        <p-column field="toke" [style]="{'width':'10%'}" sortable="custom" sortFunction="orderTable($event)" header="Token">
            <ng-template pTemplate="body" let-history="rowData">
                {{history.token}}
            </ng-template>
        </p-column>

        <p-column field="state" [style]="{'width':'10%'}" sortable="custom" sortFunction="orderTable($event)" header="Estado">
            <ng-template pTemplate="body" let-history="rowData">
                {{stateEnum[history.state]}}
            </ng-template>
        </p-column>

        <p-column field="email" header="Email" [style]="{'width':'15%'}" sortable="custom" sortFunction="orderTable($event)" >
            <ng-template pTemplate="body" let-history="rowData">                    
                {{history.email}}
            </ng-template>
        </p-column>
        
        <p-column field="create_date" header="Fecha creacion" [style]="{'width':'10%'}" sortable="custom" sortFunction="orderTable($event)">
            <ng-template pTemplate="body" let-history="rowData">
                {{history.create_date | date: 'dd/MM/yyyy'}}
            </ng-template>
        </p-column>

        <p-column field="update_date" header="Última actualización" [style]="{'width':'10%'}" sortable="custom" sortFunction="orderTable($event)">
            <ng-template pTemplate="body" let-history="rowData">
                {{history.update_date | date: 'dd/MM/yyyy'}}
            </ng-template>
        </p-column>

        <p-column header="edit" header="Edición" [style]="{'width':'15%'}">
            <ng-template pTemplate="body" let-history="rowData">
                <button (click)="previewHistory(history.url)" class="btn btn-primary p-2" type="button"  data-toggle="tooltip" data-placement="top" title="Mostrar"><i class="fas fa-search"></i></button>
                <button *ngIf="history.state!=stateEnum.Borrador" (click)="editHistory(history.token)" class="btn btn-primary p-2" type="button"  data-toggle="tooltip" data-placement="top" title="Editar"><i class="fas fa-edit"></i></button>
                <button *ngIf="history.state==stateEnum.Publicada" (click)="showActionDialog('hideHistory', history);" class="btn btn-primary p-2" type="button"  data-toggle="tooltip" data-placement="top" title="Ocultar"><i class="fas fa-eye-slash"></i></button> 
                <button *ngIf="history.state==stateEnum.Revision" (click)="showActionDialog('publishHistory', history)" class="btn btn-primary p-2" type="button"  data-toggle="tooltip" data-placement="top" title="Publicar"><i class="fas fa-eye"></i></button> 
                <!-- volver a borrador -->
                <button *ngIf="history.state==stateEnum.Revision" (click)="showActionDialog('returnToBorrador', history)" class="btn btn-primary p-2" type="button"  data-toggle="tooltip" data-placement="top" title="Pasa a borrador"><i class="fas fa-eraser"></i></button> 
                <button class="btn btn-primary p-2" type="button" (click)="showDeleteDialog(history.id)" data-toggle="tooltip" data-placement="top" title="Borrar"><i class="fas fa-trash-alt"></i></button>
            </ng-template>
        </p-column>
        
    </p-dataTable>
    <nav aria-label="Pagination" class="m-2">
        <ul class="pagination justify-content-center">
            <li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
                <a class="page-link" (click)="paginate(+pageFirst+1)" aria-label="Primero">
            <i class="fas fa-fast-backward" ></i>
            </a>
            </li>
            <li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
                <a class="page-link" (click)="paginate(+actualPage)" aria-label="Anterior">
            <i class="fas fa-step-backward"></i>
            </a>
            </li>
            <li *ngFor="let pageShow of pagesShow" [ngClass]="pageShow == '-' ? 'page-item disabled' : (pageShow == (+actualPage+1) ? 'page-item active': 'page-item')">
                <a class="page-link" (click)="paginate(pageShow)">{{pageShow}}</a>
            </li>
            <li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
                <a class="page-link" (click)="paginate(+actualPage+2)" aria-label="Siguiente">
                <i class="fas fa-step-forward"></i>
                </a>
            </li>
            <li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
                <a class="page-link" (click)="paginate(pageLast)" aria-label="Ultimo">
                <i class="fas fa-fast-forward"></i>
                </a>
            </li>
        </ul>
    </nav>
</div>
