export class Logstash {
    id_logstash: string
    portal_name: string;
    type: string;
    view: string;
    delay: string;
    status: string;
    url: string;

    constructor() { }
}
