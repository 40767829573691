<div class="containerCollaboration">
    <h1>COLABORA ¿QUÉ DATOS TE GUSTARÍA REUTILIZAR?</h1>
    <p class="description">
        Desde el apartado
        <a href="https://aragon.uservoice.com/forums/192552-datos-que-me-gustar%C3%ADa-reutilizar" target="_blank">Colabora</a> puedes sugerir qué datos y recursos te gustaría que se incluyeran en Aragón Open Data. Las peticiones
        será analizadas, respondidas y se intentará dar respuesta mediante la apertura de nuevos datos en formatos abiertos
        en Aragón Open data.
    </p>
    <a href="https://aragon.uservoice.com/forums/192552-datos-que-me-gustar%C3%ADa-reutilizar" target="_blank">
        <img src="{{imgSrc}}" width="50%">
    </a>
</div>