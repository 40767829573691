<div class="container" *ngIf="errorTitle">
  <div class="alert alert-info" role="alert">
      <div class="row">
          <div class="col-xs-3">
              <i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
          </div>
          <div class="col-xs-9">
              <h1>{{errorTitle}}</h1>
              <h2>{{errorMessage}}</h2>
              <a class="mail" href="mailto:opendata@aragon.es">
                  <h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
              </a>
          </div>
      </div>
  </div>
</div>
<div class="container" *ngIf="!errorTitle">
  <h1 tabindex="{{openedMenu ? -1 : 0}}">{{sectionTitle}}</h1>
  <h3 tabindex="{{openedMenu ? -1 : 0}}">{{sectionSubtitle}}</h3>
  <h5 tabindex="{{openedMenu || (sectionDescription == null) ? -1 : 0}}" *ngIf="!sectionDescription != null">{{sectionDescription}}</h5>
  <div id="accordion"  role="tablist"  aria-multiselectable="true">
      <div *ngFor="let content of contents">
          <div class="card">
              <div class="card-header"  role="tab"  id="{{content.contentOrder}}">
                  <div>
                      <a tabindex="{{openedMenu ? -1 : 0}}" id="{{content.targetUrl}}Link" data-toggle="collapse"  data-parent="#accordion"  href="#{{content.targetUrl}}"
                      aria-expanded="false"  aria-controls="content.contentOrder">
                          {{content.contentTitle}}
                          <i class="fas fa-plus-circle"  aria-hidden="true"></i>
                      </a>
                  </div>
              </div>
              <div id="{{content.targetUrl}}"  class="collapse"  role="tabpanel"  aria-labelledby="content.contentOrder">
                  <div class="card-block">
                      <p class="textContent" [innerHTML]="content.contentText | safeHtml"></p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>