<footer id="mainFooter" class="site-footer">
	<article class="container-fluid">
			

		<div class="row">
		<div class="col-md-4 threeCol">
			<h2 class="titleFooter">AVISO LEGAL</h2>
			<div class="threeCol_child">
				<div><a tabindex="{{openedMenu ? -1 : 0}}" href="/{{routerLinkInfoTerms}}">TERMINOS DE USO</a></div>
				 
				<div class="row" >
					<div class="col-md-3 eur-logo">
						<div class="img-eur-logo">
							<img src="{{assetsUrl}}/public/footer/images/flag-europe.png" style="padding-left: 5px;" alt="logo-union-europea">
						</div>
					</div>
					<div class="col-md-9">
						<div class="normal-txt-eur">union europea</div>
						<div class="normal-txt-eur">fondo europeo de desarrollo regional (feder)</div>
						<div class="italic-txt-eur">"Construyendo Europa desde Aragón"</div>
					</div>
						
						
				</div>
				<div class="row" >
					<div class=" col-xs-12  cc-logo">
							<div class="imgn">
								<img src="{{assetsUrl}}/public/footer/images/cc.png" width="100px" alt="logo-licencia-cc">
							</div>
					</div>
					<div class="col-xs-12 paddingleft20">
						<div class="txt-cc-logo">
							<span>
								Los contenidos del portal están sujetos a licencia creative commons reconocimiento 4.0 salvo que se indique lo contrario
							</span>
						</div>
					</div>
				</div>	
			</div>
			
			
		</div>

		<div class="col-md-4 threeCol">
			<h2 class="titleFooter">CONTENIDO DESTACADO</h2>
			<ul>
				<li><a tabindex="{{openedMenu ? -1 : 0}}" routerLink="/{{routerLinkDataCatalog}}">BANCO DE DATOS</a></li>
				<li><a tabindex="{{openedMenu ? -1 : 0}}" href="{{aodBaseUrl}}/{{routerLinkServicesOpenDataPool}}">DATOS ENLAZADOS</a></li>
				<li><a tabindex="{{openedMenu ? -1 : 0}}" href="{{aodBaseUrl}}/{{routerLinkServicesAragopedia}}">ARAGOPEDIA</a></li>
				<li><a tabindex="{{openedMenu ? -1 : 0}}" routerLink="/{{routerLinkInfoCollaboration}}">COLABORA</a></li>
				<li><a tabindex="{{openedMenu ? -1 : 0}}" routerLink="/{{routerLinkInfoApplications}}">APLICACIONES</a></li>
				<li><a tabindex="{{openedMenu ? -1 : 0}}" routerLink="/{{routerLinkInfoOpenData}}">INFO OPEN DATA</a></li>
				<li><a tabindex="{{openedMenu ? -1 : 0}}" routerLink="/{{routerLinkInfoKnowledge}}">CONOCIMIENTO</a></li>
			</ul>
		</div>

		<div class="col-md-4 threeCol logos_div">
			<h2 class="titleFooter" id="poweredByRow">POWERED BY</h2>
			
			<div class="logos paddingleft45">
				<div class="row show-grid ">
					<div class="col-xs-4 col-md-3 logos"><a tabindex="{{openedMenu ? -1 : 0}}" class="ckan" href="{{ckanUrl}}" target="_blank">
							<img src="{{assetsUrl}}/public/footer/images/cajaLogoCKAN.jpg" alt="logo-ckan">
						  </a></div>
					<div class="col-xs-4 col-md-3 logos"><a tabindex="{{openedMenu ? -1 : 0}}" class="mediawiki" href="{{mediaWikiUrl}}" target="_blank">
							<img src="{{assetsUrl}}/public/footer/images/cajaLogoMediaWiki.jpg" alt="logo-media-wiki">
						  </a></div>
					<div class="col-xs-4 col-md-3 logos">	<a tabindex="{{openedMenu ? -1 : 0}}" class="virtuoso" href="{{virtuosoUrl}}" target="_blank">
							<img src="{{assetsUrl}}/public/footer/images/cajaLogoVirtuoso.jpg" alt="logo-virtuoso">
						  </a></div>
					<div class="col-xs-4 col-md-3 logos">
							<a tabindex="{{openedMenu ? -1 : 0}}" class="elda" href="{{eldaUrl}}" target="_blank">
									<img src="{{assetsUrl}}/public/footer/images/cajaLogoElda.jpg" alt="logo-elda">
								  </a>
					</div>				
					<div class="separator"></div>
					<div class="col-xs-4 col-md-3 logos margintop10">	<a tabindex="{{openedMenu ? -1 : 0}}" class="swagger" href="{{swaggerUrl}}" target="_blank">
							<img src="{{assetsUrl}}/public/footer/images/cajaLogoSwagger.jpg" alt="logo-swagger">
						  </a></div>
					<div class="col-xs-4 col-md-3 logos margintop10"><a tabindex="{{openedMenu ? -1 : 0}}" tabindex="{{openedMenu ? -1 : 0}}" class="node" href="{{nodeJsUrl}}" target="_blank">
							<img src="{{assetsUrl}}/public/footer/images/cajaLogoNodeJS.jpg" alt="logo-nodejs">
						  </a></div>
					<div class="col-xs-4 col-md-3 logos margintop10"><a tabindex="{{openedMenu ? -1 : 0}}" class="angular" href="{{angularUrl}}" target="_blank">
							<img src="{{assetsUrl}}/public/footer/images/cajaLogoAngular.jpg" alt="logo-angular">
						  </a></div>
					<div class="col-xs-4 col-md-3 logos margintop10"><a tabindex="{{openedMenu ? -1 : 0}}" class="kibana" href="{{kibanaUrl}}" target="_blank">
							<img src="{{assetsUrl}}/public/footer/images/cajaLogoKibana.jpg" alt="logo-kibana">
						  </a></div>
				</div>



			</div>
		
		</div>
	</div>
	<div class="row separate"></div>
		<div class="row">
			<div class="col-md-8">
				<div class="twoCol">
						<img class="marginleft21" src="{{assetsUrl}}/public/footer/images/logo-aragob-blanco.png" width="150px" alt="logo-gobierno-de-aragón">
						<div class="gobText">
							Gobierno de Aragón - Edificio Pignatelli - Paseo María Agustín, 36, 50071 - Zaragoza - Tel 976714000
						</div>
					</div>
			</div>			
			<div class="col-md-4">
				<div class="social-nw">
					<div class="socialNetworkIcon">
						<a tabindex="{{openedMenu ? -1 : 0}}" class="facebook" href="{{oasiFacebookUrl}}">
							<span class="sr-only">Facebook</span>
							<i class="fab fa-facebook" aria-label="contacto-facebook"></i>
						</a>
					</div>
					<div class="socialNetworkIcon">
						<a tabindex="{{openedMenu ? -1 : 0}}" class="twitter" href="{{oasiTwitterUrl}}">
							<span class="sr-only">Twitter</span>
							<i class="fab fa-twitter" aria-label="contacto-twitter"></i>
						</a>
					</div>
					<div class="socialNetworkIcon">
						<a tabindex="{{openedMenu ? -1 : 0}}" class="youtube" href="{{oasiYoutubeUrl}}">
							<span class="sr-only">Youtube</span>
							<i class="fab fa-youtube" aria-label="contacto-youtube"></i>
						</a>
					</div>
					<div class="socialNetworkIcon">
						<a tabindex="{{openedMenu ? -1 : 0}}" class="mail" href="mailto:{{aodMail}}">
							<i class="far fa-envelope" aria-label="contacto-email"><span>AOD</span></i>
							<span class="sr-only">AOD</span>
						</a>
					</div>

				</div>
			</div>
		</div>
	</article>
<!-- Google Analytics -->
<script>
	(function (i, s, o, g, r, a, m) {
		i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
			(i[r].q = i[r].q || []).push(arguments)
		}, i[r].l = 1 * new Date(); a = s.createElement(o),
			m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
	})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
	
	ga('create', 'UA-36118896-3', 'auto');
	ga('send', 'pageview');
	
	if (typeof jQuery != 'undefined') {
		jQuery(document).ready(function ($) {
			var filetypes = /\.(rar|zip|exe|pdf|doc*|xls*|ppt*|mp3|mp4|txt|7z|bz2|tar|gz|tgz|avi|wma|flv|mpg|wmv|odt)$/i;
			var baseHref = '';
			if (jQuery('base').attr('href') != undefined)
				baseHref = jQuery('base').attr('href');
			jQuery('a').each(function () {
				var href = jQuery(this).attr('href');
				if (href && href.match(filetypes)) {
					jQuery(this).click(function () {
						var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
						var filePath = href;
						ga('send', 'event', 'File', 'Download', extension[0]);
						if (jQuery(this).attr('target') != undefined && jQuery(this).attr('target').toLowerCase() != '_blank') {
							setTimeout(function () { location.href = baseHref + href; }, 200);
							return false;
						}
					});
				}
			});
		});
	}
	</script>
	<!-- End Google Analytics -->
</footer>