<div class="container">
	<p-messages closable="true" label="Administración de usuarios" [(value)]="userAdminMessages"></p-messages>
	<p-growl sticky="true" life="2000" [(value)]="userAdminMessages"></p-growl>
	
	<p-dataTable [loading]="false" [value]="users" [responsive]="true" emptyMessage="No se han encontrado registros." (onSort)="setOrder($event)" [rows]="pageRows" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
		<p-column field="fullname" [style]="{'width':'25%'}" header="Nombre" sortable="custom" [sortable]="true"></p-column>
		<p-column field="email" [style]="{'width':'25%'}" header="Email" sortable="custom" [sortable]="true"></p-column>
		<p-column field="role" [style]="{'width':'15%'}" header="Rol" sortable="custom" [sortable]="true">
			<ng-template pTemplate="body" let-user="rowData">
				<span *ngFor="let rol of user.role">
					{{rol?.description}}
				</span>
			</ng-template>
		</p-column>
		<p-column field="signupDate" [style]="{'width':'14%'}" header="Fecha alta" sortable="custom">
			<ng-template pTemplate="body" let-user="rowData">
					{{user.signupDate | date:'dd/MM/yyyy HH:mm:ss'}}
			</ng-template>
		</p-column>
		<p-column field="active" [style]="{'width':'9%'}" header="Activo" sortable="custom">
			<ng-template pTemplate="body" let-usuario="rowData">
				<span *ngIf="user.active">Sí</span>
				<span *ngIf="!user.active">No</span>
			</ng-template>
		</p-column>
		<p-column header="Edición" [style]="{'width':'12%'}">
			<ng-template pTemplate="body" let-user="rowData">
				<button class="btn btn-primary p-2" type="button" (click)="showDialog(user, false, false)" data-toggle="tooltip" data-placement="top" title="Mostrar"><i class="fas fa-search"></i></button>
				<button class="btn btn-primary p-2" type="button" (click)="showDialog(user, true, false)" data-toggle="tooltip" data-placement="top" title="Editar"><i class="fas fa-edit"></i></button>
				<button *ngIf="user.role[0].id != 1" class="btn btn-primary p-2" type="button" (click)="showDeleteDialog(user)" data-toggle="tooltip" data-placement="top" title="Borrar"><i class="fas fa-trash-alt"></i></button>
			</ng-template>
		</p-column>
		<p-footer>
			
			<div class="ui-helper-clearfix" style="width:100%">
					<button class="btn btn-primary" type="button" icon="fas fa-plus" (click)="showDialog(null, false, true)" style="float:left" >
						<i class="fas fa-plus"></i> Nuevo Usuario
					</button>
			</div>
		</p-footer>
	</p-dataTable>
	<!-- DIALOG DE VISTA DE DATOS -->
	<p-dialog header="Detalle de usuario" appendTo="body" [(visible)]="displayViewDialog" [responsive]="true" showEffect="fade" [modal]="true" width="1000">
		<div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="user" style="font-size:16px; padding:20px;">
			<div class="ui-grid-row">
				<div class="ui-grid-col-5"><b>Nombre completo: </b></div>
				<div class="ui-grid-col-7"><p>{{user?.fullname}}</p></div>
			</div>
			<div class="ui-grid-row">
				<div class="ui-grid-col-5"><b>Usuario: </b></div>
				<div class="ui-grid-col-7"><p>{{user?.name}}</p></div>
			</div>
			<div class="ui-grid-row">
				<div class="ui-grid-col-5"><b>Email: </b></div>
				<div class="ui-grid-col-7"><p>{{user?.email}}</p></div>
			</div>
			<div class="ui-grid-row" *ngIf="displayOrg">
				<div class="ui-grid-col-5"><b>Organización: </b></div>
				<div *ngIf="userRole" class="ui-grid-col-7"><p>{{displayOrg}}</p></div>
			</div>
			<div class="ui-grid-row">
				<div class="ui-grid-col-5"><b>Rol: </b></div>
				<div *ngIf="userRole" class="ui-grid-col-7"><p>{{userRole?.description}}</p></div>
			</div>
			<div class="ui-grid-row">
				<div class="ui-grid-col-5"><b>Fecha de alta: </b></div>
				<div class="ui-grid-col-7">{{user.signupDate | date:'dd/MM/yyyy HH:mm:ss'}}</div>
			</div>
			<div class="ui-grid-row">
				<div class="ui-grid-col-5"><b>Activo: </b></div>
				<div class="ui-grid-col-7">
					<span *ngIf="user.active">Sí</span>
					<span *ngIf="!user.active">No</span>
				</div>
			</div>
			<div class="ui-grid-row">
				<div class="ui-grid-col-5"><b>Descripción: </b></div>
				<div class="ui-grid-col-7"><p>{{user?.description}}</p></div>
			</div>
		</div>
		<p-footer>
			<div class="ui-dialog-buttonpane ui-helper-clearfix">
				<button type="button" class="btn btn-danger mt-2" (click)="displayViewDialog = false"><i class="fas fa-times"></i> Cancelar</button>
				<button type="button" class="btn btn-primary mt-2" (click)="enableEdition()"><i class="fas fa-edit"></i> Editar</button>
			</div>
		</p-footer>
	</p-dialog>
	<p-dialog appendTo="body" header="{{editDialogTitle}}" [(visible)]="displayEditDialog" [responsive]="true" showEffect="fade" modal="true">
		<div class="ui-grid ui-grid-responsive ui-fluid" *ngIf="user">
			<div *ngIf="!createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label for="name">Nombre completo:</label></div>
				<div class="ui-grid-col-9"><input pInputText id="fullname" [(ngModel)]="user.fullname" /></div>
			</div>
			<div *ngIf="!createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label for="name">Usuario:</label></div>
				<div *ngIf="user.id == undefined" class="ui-grid-col-9"><input pInputText id="name" [(ngModel)]="user.name" /></div>
				<div *ngIf="user.id != undefined" class="ui-grid-col-9"><label>{{user.name}}</label></div>
			</div>
			<div *ngIf="!user.id && !createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label for="name">Contraseña:</label></div>
				<div class="ui-grid-col-9"><input type="password" feedback="true" pPassword id="password" weakLabel="Seguridad débil" 
					mediumLabel="Seguridad media" strongLabel="Seguridad fuerte" [(ngModel)]="user.password" /></div>
			</div>
			<div *ngIf="!createOrg" class="ui-grid-row" style="padding: 5px;">
					<div class="ui-grid-col-3"><label for="email">Email:</label></div>
					<div class="ui-grid-col-9"><input pInputText type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" id="email" [(ngModel)]="user.email" /></div>
				</div>
			<div *ngIf="!createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Rol:</label></div>
				<div class="ui-grid-col-9">
					<select class="form-control" [disabled]="isAdmin" [(ngModel)]="selectedRole">
						<option disabled hidden [value]="undefined">Seleccione un rol</option>
						<option *ngFor="let rol of roles" [disabled]="rol.id==1" [ngValue]="rol.id">{{rol.description}}</option>
					</select>
				</div>
			</div>
			<div *ngIf="!createOrg && !isAdmin" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Organización: </label></div>
				<div class="ui-grid-col-9">
					<select class="form-control" [(ngModel)]="selectedOrg">
						<option *ngFor="let orgSel of orgsSelect" [ngValue]="orgSel.value">{{orgSel.label}}</option>
					</select>
					<button class="btn btn-primary mt-2" (click)="changeCreateOrg()">Crear Organización</button>
				</div>
			</div>			
			<div *ngIf="createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-12"><label>Creación de Organización: </label></div>
			</div>
			<div *ngIf="createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Nombre Organización: </label></div>
				<div class="ui-grid-col-9">
					<input class="form-control" type="text" id="title" [(ngModel)]="organizationTitle" placeholder="Nombre">
				</div>
			</div>			
			<div *ngIf="createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Web Organización: </label></div>
				<div class="ui-grid-col-9">
					<input class="form-control" type="text" id="title" [(ngModel)]="organizationWeb" placeholder="Web">
				</div>
			</div>			
			<div *ngIf="createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Descripción Organización: </label></div>
				<div class="ui-grid-col-9">
					<input class="form-control" type="text" id="title" [(ngModel)]="organizationDescription" placeholder="Nombre">
				</div>
			</div>	
			<div *ngIf="createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Imagen: </label></div>
				<div class="ui-grid-col-9">
					<input type="file" class="form-control" (change)="fileChange($event)" placeholder="Subir fichero" accept="image/x-png,image/gif,image/jpeg">
				</div>
			</div>			
			<div *ngIf="createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Dirección Organización: </label></div>
				<div class="ui-grid-col-9">
					<input class="form-control" type="text" id="title" [(ngModel)]="organizationAdress" placeholder="Dirección">
				</div>
			</div>			
			<div *ngIf="createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Persona Responsable: </label></div>
				<div class="ui-grid-col-9">
					<input class="form-control" type="text" id="title" [(ngModel)]="organizationPerson" placeholder="Persona Responsable">
				</div>
			</div>							
			<div *ngIf="!createOrg && !isAdmin" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label>Activo: </label></div>
				<div class="ui-grid-col-9">
					<p-inputSwitch onLabel="Sí" offLabel="No" [(ngModel)]="activatedUser"></p-inputSwitch>
				</div>
			</div>
			<div *ngIf="!createOrg" class="ui-grid-row" style="padding: 5px;">
				<div class="ui-grid-col-3"><label for="description">Descripción: </label></div>
				<div class="ui-grid-col-9">
					<textarea pInputTextarea id="description" [(ngModel)]="user.description"></textarea>
				</div>
			</div>
		</div>
		<p-footer>
			<div *ngIf="!createOrg" class="ui-dialog-buttonpane ui-helper-clearfix">
				<button class="btn btn-danger mt-2" (click)="cancelSaveUser()"><i class="fas fa-times"></i> Cancelar</button>
				<button class="btn btn-primary mt-2" (click)="saveUser()"><i class="fas fa-save"></i> Guardar</button>
			</div>
			<div *ngIf="createOrg" class="ui-dialog-buttonpane ui-helper-clearfix">
				<button class="btn btn-danger mt-2" (click)="createOrg = false"><i class="fas fa-times"></i> Cancelar</button>
				<button class="btn btn-primary mt-2" (click)="saveOrg()"><i class="fas fa-plus"></i> Crear Organización</button>
			</div>
		</p-footer>
	</p-dialog>
	<!-- DELETE USER DIALOG -->
	<p-dialog header="¿Está seguro de que deasea borrar el usuario?" [(visible)]="displayDeleteDialog">
		<p>Se va a proceder a borrar el usuario <b>{{user?.fullname}}</b></p>
		<p>¿Desea continuar?</p>
			 <p-footer>
				 <button type="button" pButton icon="fas fa-check" (click)="deleteUser()" label="Sí"></button>
				 <button type="button" pButton icon="fas fa-times" (click)="undoDeleteUser()" label="No"></button>
			 </p-footer>
	</p-dialog>
</div>