<!-- <div class="ui-g-12">
	<p-panel header="Notificaciones">
		Content
	</p-panel>
</div>
<div class="ui-g-3">
	<p-chart type="doughnut" [data]="data"></p-chart>
	<p>Datasets por organización</p>
</div>
<div class="ui-g-5">
	<p-chart type="line" [data]="data2"></p-chart>
	<p>Visitas por mes</p>
</div>
<div class="ui-g-4">
	<p-chart type="bar" [data]="data3"></p-chart>
	<p>Contenido Campus clasificado por tipo</p>
</div> -->
<div class="col-12">
	<div class="jumbotron">
			<h1 tabindex="0" class="display-3">Bienvenido</h1>
			<p tabindex="0" class="lead">Bienvenido al portal de administración de Aragón Open Data.</p>
	</div>
</div>