<div class="container" *ngIf="errorTitle">
	<div class="alert alert-info" role="alert">
		<div class="row">
			<div class="col-xs-3">
				<i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
			</div>
			<div class="col-xs-9">
				<h1>{{errorTitle}}</h1>
				<h2>{{errorMessage}}</h2>

				<a class="mail" href="mailto:opendata@aragon.es">
					<h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
				</a>

			</div>
		</div>
	</div>
</div>
<div class="container" *ngIf="!errorTitle && datasetHomer.package_id == undefined">
<div class="dtHeader row">
	<h1 tabindex="{{openedMenu ? -1 : 0}}" class="globaltitle col-12 col-md-8">{{dataset.title}}</h1>
	<div class="social col-12 offset-md-8 col-md-4 offset-xl-0 col-xl-2">
		<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkFacebookShare}}" target="_blank" title="Compartir en Facebook">
		  <img src="{{assetsUrl}}/public/contenido-general/social/facebookIcon.png" alt="compartir-en-Facebook">
		</a>
		<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkTwitterShare}}" target="_blank" title="Compartir en Twitter">
		  <img src="{{assetsUrl}}/public/contenido-general/social/twitterIcon.png" alt="compartir-en-Twitter">
		</a>
	</div>
</div>
<button tabindex="{{openedMenu ? -1 : 0}}" *ngIf="showEdit" class="tags" icon="fas fa-pencil-alt" pButton type="button" label="Editar" style="margin-bottom: 10px;" routerLink="/admin/datacenter/datasets/edit/{{dataset.name}}"></button>	
<div class="content row">
	<div *ngIf="dataPreview" class="dtsetPreview col-12" style='margin-bottom: 10px'>
		<div class="title">
			<h5>Previsualización del conjunto de datos</h5>
		</div>
		<div class="container-fluid">
			 <ul class="nav nav-tabs" role="tablist">
				<li class="nav-item" *ngFor="let resource of resourcesAux; let resInd = index">
					<a class="nav-link" href="#resource{{resInd}}" (click)="removeResourceIframe()" role="tab" data-toggle="tab">{{resource.name}}</a>
				</li>
			</ul>
			<div class="tab-content">
				<div role="tabpanel" class="tab-pane" *ngFor="let resource of resourcesAux ; let i2 = index" id="resource{{i2}}">		
					<button *ngFor="let url of resource.sources; let i3 = index" class="tags" pButton type="button" label="{{resource.formats[i3]}}" style="margin: 2px;" (click)="removeResourceIframe();loadResourceIframe(resource, i3)"></button>
					<iframe *ngIf="iframeRes" height="650px" class="col-12 embed-responsive-item" [src]='sanitizer.bypassSecurityTrustResourceUrl(iframeRes)' frameBorder="0"></iframe>
					<h5 *ngIf="iframeError && !iframeRes">{{iframeError}}</h5>
				</div>
			</div> 
		</div>
	</div>
	<div class="infodtset col-sm-12 col-md-8">
		<div class="title">
			<h5 tabindex="{{openedMenu ? -1 : 0}}">Información del conjunto de datos</h5>
		</div>
		<div class="container-fluid">
			<div class="section" *ngIf="dataset.title">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Título</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{dataset.title}}</p>
			</div>
			<div class="section" *ngIf="dataset.notes">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Descripción</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{dataset.notes}}</p>
			</div>
			<div class="section" *ngIf="dataset.groups">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Categoría</h6>
				<p *ngFor="let group of dataset.groups">
					<a tabindex="{{openedMenu ? -1 : 0}}" routerLink='/{{routerLinkDataCatalogTopics}}/{{group.name}}'>{{group.title}}</a>
				</p>
			</div>
			<div class="section" *ngIf="dataset.tags">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Etiquetas y palabras clave</h6>
				<button tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let tag of dataset.tags" class="tags" pButton type="button" routerLink="/{{routerLinkDataCatalogTags}}" [queryParams]="{etq:tag.name}"
				 pButton label="{{tag.name}}" style="margin: 2px;"></button>
			</div>
			<div class="section" *ngIf="dataset.organization">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Organización responsable de la publicación</h6>
				<a tabindex="{{openedMenu ? -1 : 0}}" routerLink='/{{routerLinkDataOrgs}}/{{dataset.organization?.name}}' class="accesibility">{{dataset.organization?.title}}</a>
			</div>
			<div class="section">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Territorio en el que existen datos</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">Este conjunto de datos contiene datos en el territorio de {{extraTypeAragopedia}}</p>
				<p tabindex="{{openedMenu ? -1 : 0}}">Esta es la <a tabindex="{{openedMenu ? -1 : 0}}" href="{{extraUriAragopedia}}" class="accesibility"> URI del recurso {{extraShortUriAragopedia}}</a></p>
			</div>
			<div class="section" *ngIf="extraFrequency">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Frecuencia de actualización</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{extraFrequency}}</p>
			</div>
			<div class="section" *ngIf="extraTemporalFrom && extraTemporalUntil">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Periodo de tiempo en el que existen datos</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{extraTemporalFrom}} - {{extraTemporalUntil}}</p>
			</div>
			<div class="section" *ngIf="extraTemporalFrom && !extraTemporalUntil">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Periodo de tiempo en el que existen datos</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{extraTemporalFrom}}</p>
			</div>
			<div class="section" *ngIf="dataset.license_title">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Licencia</h6>
				<p><a tabindex="{{openedMenu ? -1 : 0}}" href="{{dataset.license_url}}" target="_blank" class="accesibility">{{dataset.license_title}}</a></p>
			</div>
			<div class="section" *ngIf="extraGranularity">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Nivel de detalle</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{extraGranularity}}</p>
			</div>
			<div class="section" *ngIf="extraDataQuality">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Calidad del dato</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{extraDataQuality}}</p>
				<a tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let data of extraDataQualityURL; let i = index" class="tags" (click)="submitEvent(data,'EXTRA');openUrl(data);"
				pButton (keydown.enter)="submitEvent(data,'EXTRA');openUrl(data);" type="button" label="[{{i+1}}] Documento de calidad del dato"
				style="margin: 2px;"></a>
			</div>
			<div class="section" *ngIf="extraDictionary">
				<h6 tabindex="{{openedMenu ? -1 : 0}}">Diccionario de datos</h6>
				<p tabindex="{{openedMenu ? -1 : 0}}">{{extraDictionary}}</p>
				<a tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let dic of extraDictionaryURL; let i = index" class="tags" (click)="submitEvent(dic,'EXTRA');openUrl(dic);"
				pButton (keydown.enter)="submitEvent(dic,'EXTRA');openUrl(dic);" type="button" label="[{{i+1}}] Documento de diccionario de datos"
				style="margin: 2px;"></a>
			</div>
			<div *ngIf="showMapLink">
				<a href="javascript:;" (click)="downloadMapFileAsCSV($event)">Relación entre la estructura de datos del recurso</a> y la Estructura de Información Interoperable de Aragón <a target="_blank" href="https://opendata.aragon.es/def/ei2a/">(EI2A)</a>
			</div>
		</div>
	</div>
	
	<div class="download col-sm-12 col-md-4">
		<div class="row">
			<div class="col-12">
				<div class="title">
					<h5 tabindex="{{openedMenu ? -1 : 0}}">Descargas</h5>
				</div>
				<div class="container-fluid horizontalFormat">
					<div class="section" *ngIf="resourcesAux.length != 0">
						<h6 tabindex="{{openedMenu ? -1 : 0}}">Archivos con los datos</h6>
						<div *ngFor="let resource of resourcesAux; let j = index">
							<p>{{resource.name}}</p>
							<div *ngFor="let url of resource.sources; let i = index">
								<div class="horizontalFormat" *ngIf="resource.getSourcesSize() != i+1">
									<a tabindex="{{openedMenu ? -1 : 0}}" class="tags" (click)="submitEvent(url,resource.formats[i]);" href="{{url}}" target="_blank" title="{{resource.name}}" pButton type="button" label="{{resource.formats[i]}}" style="margin: 2px;"></a>
								</div>
								<div *ngIf="resource.getSourcesSize() == i+1">
									<div *ngIf="resource.formats[i]=='px' || resource.formats[i]=='PX'">
										<a tabindex="{{openedMenu ? -1 : 0}}" class="tags" (click)="submitEvent(url,resource.formats[i]);" href="{{url}}" target="_blank" title="{{resource.name}}" pButton type="button" label="{{resource.formats[i]}}" style="margin: 2px;"></a>
										<a tabindex="{{openedMenu ? -1 : 0}}" class="tags" (click)="downloadPxFile(resourceCSVFromPX[j]);" title="{{resourceCSVFromPX[j].name}}" pButton type="button" label="{{resourceCSVFromPX[j].formats[i]}}" style="margin: 2px;"></a>
									</div>
									<div *ngIf="resource.formats[i]!='px' && resource.formats[i]!='PX'">
											<a tabindex="{{openedMenu ? -1 : 0}}" class="tags" (click)="submitEvent(url,resource.formats[i]);" href="{{url}}" target="_blank" title="{{resource.name}}" pButton type="button" label="{{resource.formats[i]}}" style="margin: 2px;"></a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="section">
						<h6 tabindex="{{openedMenu ? -1 : 0}}">Metadatos de la colección</h6>
						<a tabindex="{{openedMenu ? -1 : 0}}" class="tags" (click)="submitEvent(dataset.name,'RDF');downloadRDF(dataset.name);" target="_blank" title="Metadato de la colección en RDF" pButton type="button" label="RDF" style="margin: 2px;"></a>
					</div>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="!isMobileScreen">
			<div class="col-12 rating">
				<h6>Valora estos datos</h6>
				<p-rating [ngModel]="dataset.rating" [cancel]="false" (onRate)="rateDataset($event)"></p-rating>
			</div>
		</div>
	</div>
	<div *ngIf="extrasIAESTNotEmpty" class="infodtset col-sm-12 col-md-8" style="margin-top: 5px;">
			<div class="title">
				<h5 tabindex="{{openedMenu ? -1 : 0}}">Propiedades adicionales de los metadatos</h5>
			</div>
			<div class="container-fluid">
				<div class="section" *ngIf="extraIAESTTemaEstadistico">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Tema estadístico</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTTemaEstadistico}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTUnidadEstadistica">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Unidad Estadística</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTUnidadEstadistica}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTPoblacionEstadistica">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Población estadística</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTPoblacionEstadistica}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTUnidadMedida">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Unidad de medida</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTUnidadMedida}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTPeriodoBase">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Periodo base</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTPeriodoBase}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTTipoOperacion">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Tipo de operación</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTTipoOperacion}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTTipologiaDatosOrigen">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Tipología de datos de origen</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTTipologiaDatosOrigen}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTFuente">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Fuente</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTFuente}}</p>
				</div>
				<div class="section" *ngIf="extraIAESTTratamientoEstadistico">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Tratamiento estadístico</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}">{{extraIAESTTratamientoEstadistico}}</p>
				</div>					
				<div class="section" *ngIf="extraIAESTLegislacionUE">
					<h6 tabindex="{{openedMenu ? -1 : 0}}">Legislación UE</h6>
					<p tabindex="{{openedMenu ? -1 : 0}}" class="textContent" [innerHTML]="extraIAESTLegislacionUE"></p>
				</div>					
			</div>
	</div>
	<div class="col-12 rating" *ngIf="isMobileScreen">
		<h6>Valora estos datos</h6>
		<p-rating [ngModel]="dataset.rating" [cancel]="false" (onRate)="rateDataset($event)"></p-rating>
	</div>
	<div class="recomend col-12" *ngIf="datasetsRecommended">
		<h4 tabindex="{{openedMenu ? -1 : 0}}">También te pueden interesar estos datos</h4>

		 <div class="ui-g">
				<div class="ui-sm-12 ui-md-6 ui-lg-4 tarjetaContainer" *ngFor="let dsRecommended of datasetsRecommended; let i = index">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="recommendedCard" routerLink="/datos/catalogo/dataset/{{dsRecommended.name}}">
						<div class="wrapper" *ngFor="let topic of dsRecommended.groups">							
							<div class="cardSize">
								<img class="card-img-top" src="{{topic.image_url}}" alt="{{topic.title | uppercase}}" width="fit-content" height="fit-content">
								<p>{{topic.title}}</p>
								<div class="recommended-card-block">
									<p>{{dsRecommended.title}}</p>
								</div>
							</div>
							<div class="detail bg-faded">
									<h6 class="back-title">TÍTULO</h6>
									<h6 class="back-value">{{dsRecommended.title}}</h6>
									<h6 class="back-title">CATEGORÍA</h6>
									<h6 class="back-value">{{topic.title}}</h6>
									<h6 class="back-title">PUBLICADOR</h6>
									<h6 class="back-value">{{dsRecommended.organization.title}}</h6>
							</div>
						</div>
					</div>	
				</div>
			</div>
	</div>
</div>
</div>
<div *ngIf="!errorTitle && datasetHomer.package_id != undefined" class="container">
<div class="dtHeader row">
	<h1 tabindex="{{openedMenu ? -1 : 0}}" *ngIf="datasetHomer.title" class="globaltitle col-10">{{datasetHomer.title}}</h1>
	<div class="social col-2">
		<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkFacebookShare}}" target="_blank" title="Compartir en Facebook">
		  <img src="{{assetsUrl}}/public/contenido-general/social/facebookIcon.png" alt="compartir-en-Facebook">
		</a>
		<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkTwitterShare}}" target="_blank" title="Compartir en Twitter">
		  <img src="{{assetsUrl}}/public/contenido-general/social/twitterIcon.png" alt="compartir-en-Twitter">
		</a>
	</div>
</div>
<div class="content row">
	<div class="infodtset col-sm-12 col-md-8">
		<div class="title">
			<h5>Información del conjunto de datos Homer</h5>
		</div>
		<div class="container-fluid">
			<div class="section" *ngIf="datasetHomer.title">
				<h6>Título</h6>
				<p>{{datasetHomer.title}}</p>
			</div>
			<div class="section" *ngIf="datasetHomer.description">
				<h6>Descripción</h6>
				<div [innerHTML]="datasetHomer.description"></div>
			</div>
			<div class="section" *ngIf="datasetHomer.tag && datasetHomer.tag.length != 0">
				<h6>Palabra clave / etiqueta</h6>
				<div class="row" style="padding:10px">
				<span *ngFor="let tag of datasetHomer.tag" class="tagsHomer">{{tag}}</span>
				</div>
			</div>
			<div class="section" *ngIf="datasetHomer.author && datasetHomer.author.length != 0">
				<h6>Publicador</h6>
				<p *ngFor="let author of datasetHomer.author">{{author}}</p>
			</div>
			<div class="section" *ngIf="datasetHomer.language">
				<h6>Idioma</h6>
				<p>{{datasetHomer.language}}</p>
			</div>
			<div class="section" *ngIf="datasetHomer.license_id">
				<h6>Licencia</h6>
				<p>{{datasetHomer.license_id}}</p>
			</div>
			<div class="section" *ngIf="datasetHomer.topic && datasetHomer.topic.length != 0">
				<h6>Temas</h6>
				<p *ngFor="let topic of datasetHomer.topic">{{topic}}</p>
			</div>
			<div class="section" *ngIf="datasetHomer.portal">
				<h6>Portal</h6>
				<p>{{datasetHomer.portal}}</p>
			</div>
		</div>
	</div>
	<div class="download col-sm-12 col-md-4" *ngIf="datasetHomer.metadata_origin || datasetHomer.url">
		<div class="title">
			<h5>Descargas</h5>
		</div>
		<div class="container-fluid horizontalFormat">
			<div class="section" *ngIf="datasetHomer.metadata_origin">
				<h6>Metadato original</h6>
				<button class="tags" pButton type="button" pButton label="Metadato" (click)="openUrl(datasetHomer.metadata_origin)"
				target="_blank" style="margin: 2px;"></button>
			</div>
			<div class="section" *ngIf="datasetHomer.url">
				<h6>Dato</h6>
				<button class="tags" pButton type="button" pButton label="Dato" (click)="openUrl(datasetHomer.url)" style="margin: 2px;"></button>
			</div>
		</div>
	</div>
</div>
</div>
<p-toast [style]="{marginTop: '15vh'}"></p-toast>