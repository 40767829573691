<!-- Page Content -->
<div class="container-fluid marginsup">
	<div class="row">
		<div class=" col-md-9">
			<h1 tabindex="{{openedMenu ? -1 : 0}}" class="firstTitle">AOD CAMPUS</h1>

		</div>
	</div>
	<div class="row">
		<div class=" col-md-8 columns">
			<h6 tabindex="{{openedMenu ? -1 : 0}}" class="campusTitle">En Campus Open Data queremos que tengas a mano toda la información que hemos ido generando a lo largo del tiempo. Nuestra
				intención es que la información sirva para que te puedas formar y que los materiales sean tutoriales para entender qué
				son los datos abiertos y cómo los datos abiertos sirven para fomentar la economía digital y mejorar la transparencia
				del Gobierno. Tenemos materiales de todo tipo: vídeos, presentaciones, infografías y hasta un curso completo de iniciación
				a Open Data. Te invitamos a que pruebes lo que más te interese</h6>

		</div>
	</div>
	<div class="row">
		<!-- Blog Entries Column -->
		<div *ngIf="errorTitle" class="alert alert-info col-md-8" role="alert">
			<div class="col-xs-3">
				<i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
			</div>
			<div class="col-xs-9">
				<h1>{{errorTitle}}</h1>
				<h2>{{errorMessage}}</h2>
				<a class="mail" href="mailto:opendata@aragon.es">
					<h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
				</a>
			</div>
		</div>
		
		<div *ngIf="!errorTitle" class="col-md-8 columns">
			<div *ngIf="campusNoRows" class="alert alert-info">
				<div class="col-xs-9">
					{{campusNoRowsMessage}}
				</div>
			</div>
			<!-- Blog Post -->

			<div *ngFor="let event of eventsList; let i = index" class="card mb-4">
				<div class="card-body">
					<h2 tabindex="{{openedMenu ? -1 : 0}}" class="card-title">{{event?.name}}</h2>
					<div tabindex="{{openedMenu ? -1 : 0}}" class="media description">
						{{event?.description}}

					</div>
					<div *ngFor="let content of event.contents" class="media">
						<a class="pull-left">
							<img class="media-object" [src]='sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64,"+content?.thumbnail)' style="width: 150px;margin-right: 15px;">
						</a>
						<div class="media-body">
							<h4 class="media-heading">
								<a tabindex="{{openedMenu ? -1 : 0}}" routerLink='/{{routerLinkCampusDetail}}/{{content?.id}}'>
									{{content?.title}}
								</a>
							</h4>
							<div tabindex="{{openedMenu ? -1 : 0}}" class="description">
								{{content?.description}}
							</div>
						</div>
					</div>
				</div>
				<div tabindex="{{openedMenu ? -1 : 0}}" class="card-footer text-muted">
					Publicado el {{event.date | date: 'dd/MM/yyyy'}} - {{event.site}}
				</div>
			</div>
			<!-- Pagination-->
			<ul class="pagination justify-content-center mb-4">
				<li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
					<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+pageFirst+1)" aria-label="Primero">
					<i class="fas fa-fast-backward" ></i>
					</a>
				</li>
				<li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
					<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage)" aria-label="Anterior">
					<i class="fas fa-step-backward"></i>
					</a>
				</li>
				<li *ngFor="let pageShow of pagesShow" [ngClass]="pageShow == '-' ? 'page-item disabled' : (pageShow == (+actualPage+1) ? 'page-item active': 'page-item')">
					<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageShow)">{{pageShow}}</a>
				</li>
				<li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
					<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage+2)" aria-label="Siguiente">
						<i class="fas fa-step-forward"></i>
						</a>
				</li>
				<li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
					<a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageLast)" aria-label="Ultimo">
						<i class="fas fa-fast-forward"></i>
						</a>
				</li>
			</ul>
		</div>

		<!-- Sidebar Widgets Column -->
		<div class="col-md-3 columns">

			<!-- Search Widget -->
			<div class="card my-4">
				<h5 tabindex="{{openedMenu ? -1 : 0}}" class="card-header">Búsqueda</h5>
				<div class="card-body">
					<div class="input-group">
						<input tabindex="{{openedMenu ? -1 : 0}}" type="text" class="form-control" [value]="searchValue" placeholder="Buscar por..." (keyup.enter)="getCampusEventsBySearch(null,null,searchInput.value)"
						 #searchInput>
						<span class="input-group-btn">
						  <button tabindex="{{openedMenu ? -1 : 0}}" class="btn btn-secondary" type="button" (click)="getCampusEventsBySearch(null,null,searchInput.value)">Buscar</button>
						</span>
					</div>
				</div>
			</div>

			<!-- Type Widget -->
			<div class="card my-4">
				<h5 tabindex="{{openedMenu ? -1 : 0}}" class="card-header">Eventos</h5>
				<div class="card-body">
					<div class="input-group" *ngIf="!errorTitle">
						<select tabindex="{{openedMenu ? -1 : 0}}" class="form-control" [(ngModel)]="selectedType">
							<option *ngFor="let types of typesSelect" [ngValue]="types.value">{{types.label}}</option>
						</select>
						<span class="input-group-btn">
							<button tabindex="{{openedMenu ? -1 : 0}}" class="btn btn-secondary" type="button" (click)="getCampusEvents(null,null)">Buscar</button>
						</span>
					</div>
					<div class="input-group" *ngIf="errorTitle">
						<p>{{errorTitle}}</p>
					</div>
				</div>
			</div>

		</div>

	</div>
	<!-- /.row -->

</div>
<!-- /.container -->