<div class="container" *ngIf="errorTitle">
  <div class="alert alert-info" role="alert">
      <div class="row">
          <div class="col-xs-3">
              <i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
          </div>
          <div class="col-xs-9">
              <h1>{{errorTitle}}</h1>
              <h2>{{errorMessage}}</h2>
              <a class="mail" href="mailto:opendata@aragon.es">
                  <h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
              </a>
          </div>
      </div>
  </div>
</div>
<div class="background">
  <div class="container-fluid marginsup" *ngIf="!errorTitle">
    <p class="textContent" [innerHTML]="content?.contentText | safeHtml"></p>
  </div>
</div>
