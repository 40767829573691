<div class="container" *ngIf="errorTitle">
	<div class="alert alert-info" role="alert">
		<div class="row">
			<div class="col-xs-3">
				<i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
			</div>
			<div class="col-xs-9">
				<h1>{{errorTitle}}</h1>
				<h2>{{errorMessage}}</h2>

				<a class="mail" href="mailto:opendata@aragon.es">
					<h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
				</a>

			</div>
		</div>
	</div>
</div>
<div class="container" *ngIf="!errorTitle">
<div class="dtHeader row">
	<h1 class="globaltitle col-10">{{dataset.title}}</h1>
	<div *ngIf="datasetLoaded" class="social col-2">
		<a href="{{routerLinkFacebookShare}}" target="_blank" title="Compartir en Facebook">
		  <img src="{{assetsUrl}}/public/contenido-general/social/facebookIcon.png" alt="compartir-en-Facebook">
		</a>
		<a href="{{routerLinkTwitterShare}}" target="_blank" title="Compartir en Twitter">
		  <img src="{{assetsUrl}}/public/contenido-general/social/twitterIcon.png" alt="compartir-en-Twitter">
		</a>
	</div>
</div>
<button *ngIf="showEdit" id="editButton" type="button" class="btn btn-primary" routerLink="/admin/datacenter/datasets/edit/{{dataset.name}}"><i class="fas fa-pencil-alt"></i> Editar</button>
<div class="content row">
	<div *ngIf="dataPreview" class="dtsetPreview col-12" style='margin-bottom: 10px'>
		<div class="title">
			<h5>Previsualización del conjunto de datos</h5>
		</div>
		<div class="container-fluid">
			 <ul class="nav nav-tabs" role="tablist">
				<li class="nav-item" *ngFor="let resource of resourcesAux; let resInd = index">
					<a class="nav-link" href="#resource{{resInd}}" (click)="removeResourceIframe()" role="tab" data-toggle="tab">{{resource.name}}</a>
				</li>
			</ul>
			<div class="tab-content">
				<div role="tabpanel" class="tab-pane" *ngFor="let resource of resourcesAux ; let i2 = index" id="resource{{i2}}">		
					<button *ngFor="let url of resource.sources; let i3 = index" type="button" class="btn btn-primary m-1" (click)="removeResourceIframe();loadResourceIframe(resource, i3)">{{resource.formats[i3]}}</button>
					<iframe *ngIf="iframeRes" height="650px" class="col-12 embed-responsive-item" [src]='sanitizer.bypassSecurityTrustResourceUrl(iframeRes)' frameBorder="0"></iframe>
					<h5 *ngIf="iframeError && !iframeRes">{{iframeError}}</h5>
				</div>
			</div> 
		</div>
	</div>
	<div class="infodtset col-sm-12 col-md-8">
		<div class="card p-2">
			<h3 class="card-header">Información del conjunto de datos</h3>
			<div class="card-block mb-2">
			  <h4 class="card-title" *ngIf="dataset.title">Titulo</h4>
			  <p class="card-text">{{dataset.title}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="dataset.notes">
				<h4 class="card-title">Descripción</h4>
				<p class="card-text">
					{{dataset.notes}}
				</p>
			</div>
			<div class="card-block mb-2" *ngIf="dataset.groups">
				<h4 class="card-title">Categoría</h4>
				<p *ngFor="let group of dataset.groups">
					<a routerLink='/{{routerLinkDataCatalogTopics}}/{{group.name}}'>{{group.title}}</a>
				</p>
			</div>
			<div class="card-block mb-2" *ngIf="dataset.tags">
				<h4 class="card-title">Etiquetas y palabras clave</h4>
				<h3>
					<span *ngFor="let tag of dataset.tags" class="badge badge-pill badge-secondary m-1" routerLink="/{{routerLinkDataCatalogTags}}" [queryParams]="{etq:tag.name}">{{tag.display_name}}</span>
				</h3>
			</div>
			<div class="card-block mb-2" *ngIf="dataset.organization">
				<h4 class="card-title">Organización responsable de la publicación</h4>
				<a routerLink='/{{routerLinkDataOrgs}}/{{dataset.organization?.name}}' class="accesibility">{{dataset.organization?.title}}</a>
			</div>
			<div class="card-block mb-2">
				<h4 class="card-title">Territorio en el que existen datos</h4>
				<p class="card-text">Este conjunto de datos contiene datos en el territorio de {{extraTypeAragopedia}}</p>
				<p class="card-text">Esta es la <a href="{{extraUriAragopedia}}" class="accesibility"> URI del recurso {{extraShortUriAragopedia}}</a></p>
			</div>
			<div class="card-block mb-2" *ngIf="extraFrequency">
				<h4 class="card-title">Frecuencia de actualización</h4>
				<p class="card-text">
					{{extraFrequency}}
				</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraTemporalFrom && extraTemporalUntil">
				<h4 class="card-title">Periodo de tiempo en el que existen datos</h4>
				<p class="card-text">
					De {{extraTemporalFrom}} a {{extraTemporalUntil}}
				</p>
			</div>
			<div class="card-block mb-2" *ngIf="dataset.license_title">
				<h4 class="card-title">Licencia</h4>
				<p class="card-text">
					<a href="{{dataset.license_url}}" target="_blank" class="accesibility">{{dataset.license_title}}</a>
				</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraGranularity">
				<h4 class="card-title">Nivel de detalle</h4>
				<p class="card-text">
					{{extraGranularity}}
				</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraDataQuality">
				<h4 class="card-title">Calidad del dato</h4>
				<p class="card-text">{{extraDataQuality}}</p>
				<a *ngFor="let data of extraDataQualityURL; let i = index" class="tags" (click)="openUrl(data);"
				pButton type="button" label="[{{i+1}}] Documento de calidad del dato"
				style="margin: 2px;"></a>
			</div>
			<div class="card-block mb-2" *ngIf="extraDictionary">
				<h4 class="card-title">Diccionario de datos</h4>
				<p class="card-text">{{extraDictionary}}</p>
				<a *ngFor="let dic of extraDictionaryURL; let i = index" class="tags" (click)="openUrl(dic);"
				pButton type="button" label="[{{i+1}}] Documento de diccionario de datos"
				style="margin: 2px;"></a>
			</div>
			<div *ngIf="showMapLink">
				<!-- <h4 *ngIf="showMapLink && !extraDictionary" class="card-title">Diccionario de datos</h4> -->
				<a href="javascript:;" (click)="downloadMapFileAsCSV($event)">Relación entre la estructura de datos del recurso</a> y la Estructura de Información Interoperable de Aragón <a target="_blank" href="https://opendata.aragon.es/def/ei2a/">(EI2A)</a>
			</div>
		</div>
	</div>
	
	<div class="download col-sm-12 col-md-4">
		<div class="card p-2">
			<h3 class="card-header">Descargas</h3>
			<div class="card-block" *ngIf="!resourcesEmpty">
				<h4 class="card-title">Archivos con los datos</h4>
				<div *ngFor="let resource of resourcesAux">
					<p class="card-text">{{resource.name}}</p>
					<div *ngFor="let url of resource.sources; let i = index">
						<div class="horizontalFormat" *ngIf="resource.getSourcesSize() != i+1">
							<a class="tags" href="{{url}}" target="_blank" title="{{resource.name}}" pButton type="button" label="{{resource.formats[i]}}" style="margin: 2px;"></a>
						</div>
						<div *ngIf="resource.getSourcesSize() == i+1">
							<a class="tags" href="{{url}}" target="_blank" title="{{resource.name}}" pButton type="button" label="{{resource.formats[i]}}" style="margin: 2px;"></a>
						</div>
					</div>
				</div>
			</div>
			<div class="card-block">
				<h4 class="card-title">Metadatos de la colección</h4>
				<a class="tags" (click)="downloadRDF(dataset.name)" target="_blank" title="Metadato de la colección en RDF" pButton type="button" label="RDF" style="margin: 2px;"></a>
			  </div>
		</div>
	</div>
	<div *ngIf="extrasIAESTNotEmpty" class="infodtset col-sm-12 col-md-8" style="margin-top: 5px;">
		<div class="card p-2">
			<h3 class="card-header">Propiedades adicionales de los metadatos</h3>
			<div class="card-block mb-2" *ngIf="extraIAESTTemaEstadistico">
				<h4 class="card-title" >Tema estadístico</h4>
				<p class="card-text">{{extraIAESTTemaEstadistico}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTUnidadEstadistica">
				<h4 class="card-title" >Unidad Estadística</h4>
				<p class="card-text">{{extraIAESTUnidadEstadistica}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTPoblacionEstadistica">
				<h4 class="card-title" >Población estadística</h4>
				<p class="card-text">{{extraIAESTPoblacionEstadistica}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTUnidadMedida">
				<h4 class="card-title" >Unidad de medida</h4>
				<p class="card-text">{{extraIAESTUnidadMedida}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTPeriodoBase">
				<h4 class="card-title" >Periodo base</h4>
				<p class="card-text">{{extraIAESTPeriodoBase}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTTipoOperacion">
				<h4 class="card-title" >Tipo de operación</h4>
				<p class="card-text">{{extraIAESTTipoOperacion}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTTipologiaDatosOrigen">
				<h4 class="card-title" >Tipología de datos de origen</h4>
				<p class="card-text">{{extraIAESTTipologiaDatosOrigen}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTFuente">
				<h4 class="card-title" >Fuente</h4>
				<p class="card-text">{{extraIAESTFuente}}</p>
			</div>
			<div class="card-block mb-2" *ngIf="extraIAESTTratamientoEstadistico">
				<h4 class="card-title" >Tratamiento estadístico</h4>
				<p>{{extraIAESTTratamientoEstadistico}}</p>
			</div>					
			<div class="card-block mb-2" *ngIf="extraIAESTLegislacionUE">
				<h4 class="card-title" >Legislación UE</h4>
				<p class="card-text" [innerHTML]="extraIAESTLegislacionUE"></p>
			</div>
		</div>
	</div>
	<div class="col-12">
		<div class="row justify-content-end p-5">
			<div class="btn-group" role="group" aria-label="Action Buttons">
				<button type="button" (click)="goToDatasetList()" class="btn btn-danger"><i class="fas fa-arrow-left" ></i> Volver</button>
			</div>
		</div>
	</div>	
</div>
</div>