<div>
	<div class="jumbotron-fluid searchMain" id="searchMain">
		<div class="back"></div>
		<div class="searcher">
			<dataset-autocomplete id="autocomplete"></dataset-autocomplete>
		</div>
		<div class="cont">
			<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary btn-outline goDown" (click)="move('dataCenter')">
				<img src="{{assetsUrl}}/public/i/flechaDown_home.png" alt="avanzar-a-sección-datos" />
			</button>
		</div>
	</div>
	<div class="jumbotron-fluid dataCenter" id="dataCenter">
		<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary goUp" (click)="move('searchMain')">
			<img src="{{assetsUrl}}/public/i/flechaUp_home.png" alt="retroceder-a-sección-buscador-principal" />
		</button>
		<div class="contenido">
			<h1 tabindex="{{openedMenu ? -1 : 0}}">Datos</h1>
			<p tabindex="{{openedMenu ? -1 : 0}}" class="titleDesc">
				Lo más importante de Aragón Open Data son sus datos y los hemos ordenado para que puedas encontrarlos fácilmente.
			</p>
			<div class="ui-g">
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkDataCatalog}}" routerLinkActive="active" (mouseenter)="hover('#imgBD','/static/public/i/cards/Card-01-BancoDatos-ON.jpg')"
					 (mouseleave)="unhover('#imgBD','/static/public/i/cards/Card-01-BancoDatos-OFF.jpg')">
						<img class="card-img" id="imgBD" src="{{assetsUrl}}/public/i/cards/Card-01-BancoDatos-OFF.jpg" alt="banco-de-datos">
						<div *ngIf="hovers[0].hover" class="card-img-overlay" style="text-align: center;">
							<h2>BANCO DE DATOS</h2>
							<p>Además de buscar, puedes acceder a los datos mediante categorías y filtros.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkDataTopics}}" routerLinkActive="active" (mouseenter)="hover('#imgTem', '/static/public/i/cards/Card-02-Temas-ON.jpg')"
					 (mouseleave)="unhover('#imgTem', '/static/public/i/cards/Card-02-Temas-OFF.jpg')">
						<img class="card-img" id="imgTem" src="{{assetsUrl}}/public/i/cards/Card-02-Temas-OFF.jpg" alt="temas">
						<div *ngIf="hovers[1].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Temas</h2>
							<p>Puedes llegar a los datos mediante la navegación temática.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkDataOrganizations}}" routerLinkActive="active" (mouseenter)="hover('#imgPub', '/static/public/i/cards/Card-03-Publicadores-ON.jpg')"
					 (mouseleave)="unhover('#imgPub', '/static/public/i/cards/Card-03-Publicadores-OFF.jpg')">
						<img class="card-img" id="imgPub" src="{{assetsUrl}}/public/i/cards/Card-03-Publicadores-OFF.jpg" alt="publicadores">
						<div *ngIf="hovers[2].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Publicadores</h2>
							<p>Conoce qué organizaciones aportan sus datos a Aragón Open Data.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary goDown" (click)="move('services')">
			<img src="{{assetsUrl}}/public/i/flechaDown_home.png" alt="avanzar-a-sección-servicios" />
		</button>
	</div>
	<div class="jumbotron-fluid services" id="services">
		<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary goUp" (click)="move('dataCenter')">
			<img src="{{assetsUrl}}/public/i/flechaUp_home.png" alt="retroceder-a-sección-datos" />
		</button>
		<div class="contenido">
			<h1 tabindex="{{openedMenu ? -1 : 0}}">Servicios</h1>
			<p tabindex="{{openedMenu ? -1 : 0}}" class="titleDesc">
				En Aragón Open Data hemos creado servicios para ofrecer los datos, reutilizarlos y acceder de forma sencilla a la información.
			</p>
			<div class="ui-g">
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkServicesOpenDataPool)" (click)="goToUrl(routerLinkServicesOpenDataPool)" (mouseenter)="hover('#imgPool','/static/public/i/cards/Card-21-Datos-Enlazados-ON.jpg')"
					 (mouseleave)="unhover('#imgPool','/static/public/i/cards/Card-21-Datos-Enlazados-OFF.jpg')">
						<img class="card-img" id="imgPool" src="{{assetsUrl}}/public/i/cards/Card-21-Datos-Enlazados-OFF.jpg" alt="datos-enlazados">
						<div *ngIf="hovers[18].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Datos Enlazados</h2>
							<p>Descubre nueva información navegando por los datos abiertos enlazados.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkServicesAragopedia)" (click)="goToUrl(routerLinkServicesAragopedia)" (mouseenter)="hover('#imgAragopedia','/static/public/i/cards/Card-05-Aragopedia-ON.jpg')"
					 (mouseleave)="unhover('#imgAragopedia','/static/public/i/cards/Card-05-Aragopedia-OFF.jpg')">
						<img class="card-img" id="imgAragopedia" src="{{assetsUrl}}/public/i/cards/Card-05-Aragopedia-OFF.jpg" alt="aragopedia">
						<div *ngIf="hovers[3].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Aragopedia</h2>
							<p>Entiende y usa los datos abiertos de Aragón Open Data a través de los municipios y comarcas de Aragón.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkServicesPresupuestos)" (click)="goToUrl(routerLinkServicesPresupuestos)" (mouseenter)="hover('#imgPre', '/static/public/i/cards/Card-06-Presupuestos-ON.jpg')"
					 (mouseleave)="unhover('#imgPre', '/static/public/i/cards/Card-06-Presupuestos-OFF.jpg')">
						<img class="card-img" id="imgPre" src="{{assetsUrl}}/public/i/cards/Card-06-Presupuestos-OFF.jpg" alt="presupuestos">
						<div *ngIf="hovers[4].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Presupuestos</h2>
							<p>Conoce de un modo claro cómo se distribuye el presupuesto de Aragón y de dónde vienen los ingresos y a qué destinamos
								el gasto.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkServicesAnalytics}}" (mouseenter)="hover('#imgAna', '/static/public/i/cards/Card-18-Analytics-ON.jpg')"
					 (mouseleave)="unhover('#imgAna', '/static/public/i/cards/Card-18-Analytics-OFF.jpg')">
						<img class="card-img" id="imgAna" src="{{assetsUrl}}/public/i/cards/Card-18-Analytics-OFF.jpg" alt="open-analytics-data">
						<div *ngIf="hovers[16].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Open Analytics Data</h2>
							<p>Conoce los datos de uso y acceso a las webs más importantes del Gobierno de Aragón.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkServicesSocialData)" (click)="goToUrl(routerLinkServicesSocialData)" (mouseenter)="hover('#imgOSD', '/static/public/i/cards/Card-04-OpenSocialData-ON.jpg')"
					 (mouseleave)="unhover('#imgOSD', '/static/public/i/cards/Card-04-OpenSocialData-OFF.jpg')">
						<img class="card-img" id="imgOSD" src="{{assetsUrl}}/public/i/cards/Card-04-OpenSocialData-OFF.jpg" alt="open-social-data">
						<div *ngIf="hovers[5].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Open Social Data</h2>
							<p>Escucha, visualiza y analiza las conversaciones sobre Aragón en las redes sociales y conoce qué está pasando.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<!-- <div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkServicesVisual)" (click)="goToUrl(routerLinkServicesVisual)" (mouseenter)="hover('#imgVis', '/static/public/i/cards/Card-19-Visual-ON.PNG')"
					 (mouseleave)="unhover('#imgVis', '/static/public/i/cards/Card-19-Visual-OFF.png')">
						<img class="card-img" id="imgVis" src="{{assetsUrl}}/public/i/cards/Card-19-Visual-OFF.png" alt="open-visual-data">
						<div *ngIf="hovers[17].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Open Visual Data</h2>
							<p>Crea gráficos y visualizaciones con los datos disponibles en Aragón Open Data.</p>
						</div>
					</div> -->
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkServicesFocus)" (click)="goToUrl(routerLinkServicesFocus)" (mouseenter)="hover('#imgVis', '/static/public/i/cards/Card-19-Visual-ON.PNG')"
					 (mouseleave)="unhover('#imgVis', '/static/public/i/cards/Card-19-Visual-OFF.png')">
						<img class="card-img" id="imgVis" src="{{assetsUrl}}/public/i/cards/Card-19-Visual-OFF.png" alt="open data focus">
						<div *ngIf="hovers[17].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Open Data Focus</h2>
							<p>Open Data Focus te permite crear historias y gráficos, en la versión ordenador, con los datos abiertos que dispone Aragón Open Data.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkServicesCras)" (click)="goToUrl(routerLinkServicesCras)" (mouseenter)="hover('#imgCRAs', '/static/public/i/cards/Card-07-CRAs-ON.jpg')"
					 (mouseleave)="unhover('#imgCRAs', '/static/public/i/cards/Card-07-CRAs-OFF.jpg')">
						<img class="card-img" id="imgCRAs" src="{{assetsUrl}}/public/i/cards/Card-07-CRAs-OFF.jpg" alt="CRAs">
						<div *ngIf="hovers[6].hover" class="card-img-overlay" style="text-align: center;">
							<h2>CRAs Aragón</h2>
							<p>Descubre más sobre los Colegios Rurales Agrupados de Aragón.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary goDown" (click)="move('info')">
			<img src="{{assetsUrl}}/public/i/flechaDown_home.png" alt="avanzar-a-sección-información" />
		</button>
	</div>
	<div class="jumbotron-fluid info" id="info">
		<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary goUp" (click)="move('services')">
			<img src="{{assetsUrl}}/public/i/flechaUp_home.png" alt="retroceder-a-sección-servicios" />
		</button>
		<div class="contenido">
			<h1 tabindex="{{openedMenu ? -1 : 0}}" class="titleDesc">Información</h1>
			<p tabindex="{{openedMenu ? -1 : 0}}">
				Te explicamos qué es Aragón Open Data, qué hacemos y cómo puedes sumarte y colaborar.
			</p>
			<div class="ui-g">
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkInfoOpenData}}" (mouseenter)="hover('#imgInfo','/static/public/i/cards/Card-10-Informacion-ON.jpg')"
					 (mouseleave)="unhover('#imgInfo','/static/public/i/cards/Card-10-Informacion-OFF.jpg')">
						<img class="card-img" id="imgInfo" src="{{assetsUrl}}/public/i/cards/Card-10-Informacion-OFF.jpg" alt="información">
						<div *ngIf="hovers[7].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Información</h2>
							<p>Conoce más sobre el entorno open data y qué hacemos en Aragón.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkInfoKnowledge}}" (mouseenter)="hover('#imgCono', '/static/public/i/cards/Card-20-Conocimiento-ON.jpg')"
					 (mouseleave)="unhover('#imgCono', '/static/public/i/cards/Card-20-Conocimiento-OFF.jpg')">
						<img class="card-img" id="imgCono" src="{{assetsUrl}}/public/i/cards/Card-20-Conocimiento-OFF.jpg" alt="conocimiento">
						<div *ngIf="hovers[19].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Conocimiento</h2>
							<p>Entiende e interpreta los datos a través de sus ontologías, vocabularios y ejemplos de consulta.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkInfoApplications}}" (mouseenter)="hover('#imgApp', '/static/public/i/cards/Card-09-Aplicaciones-ON.jpg')"
					 (mouseleave)="unhover('#imgApp', '/static/public/i/cards/Card-09-Aplicaciones-OFF.jpg')">
						<img class="card-img" id="imgApp" src="{{assetsUrl}}/public/i/cards/Card-09-Aplicaciones-OFF.jpg" alt="aplicaciones">
						<div *ngIf="hovers[8].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Aplicaciones</h2>
							<p>Otras aplicaciones que aprovechan y reutilizan nuestros datos para ofrecértelos.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkInfoEventos}}" (mouseenter)="hover('#imgEv', '/static/public/i/cards/Card-11-Eventos-ON.jpg')"
					 (mouseleave)="unhover('#imgEv', '/static/public/i/cards/Card-11-Eventos-OFF.jpg')">
						<img class="card-img" id="imgEv" src="{{assetsUrl}}/public/i/cards/Card-11-Eventos-OFF.jpg" alt="eventos">
						<div *ngIf="hovers[9].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Eventos</h2>
							<p>Agenda y actividades de Aragón Open Data.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkInfoCollaboration}}" (mouseenter)="hover('#imgCol', '/static/public/i/cards/Card-08-Colabora-ON.jpg')"
					 (mouseleave)="unhover('#imgCol', '/static/public/i/cards/Card-08-Colabora-OFF.jpg')">
						<img class="card-img" id="imgCol" src="{{assetsUrl}}/public/i/cards/Card-08-Colabora-OFF.jpg" alt="colabora">
						<div *ngIf="hovers[10].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Colabora</h2>
							<p>Sugiere datos, formatos y utilidades que te gustaría disponer de Aragón Open Data.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary goDown" (click)="move('tools')">
			<img src="{{assetsUrl}}/public/i/flechaDown_home.png" alt="avanzar-a-sección-herramientas" />
		</button>
	</div>
	<div class="jumbotron-fluid tools" id="tools">
		<button tabindex="{{openedMenu ? -1 : 0}}" type="button" class="btn btn-secondary goUp" (click)="move('info')">
			<img src="{{assetsUrl}}/public/i/flechaUp_home.png" alt="retroceder-a-sección-información" />
		</button>
		<div class="contenido">
			<h1 tabindex="{{openedMenu ? -1 : 0}}" class="titleDesc">Herramientas</h1>
			<p tabindex="{{openedMenu ? -1 : 0}}">
				Aprende y consigue nuestros contenidos, datos y código con estas utilidades hechas para ti.
			</p>
			<div class="ui-g">
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkToolsCampus}}" (mouseenter)="hover('#imgCampus','/static/public/i/cards/Card-12-Campus-ON.jpg')"
					 (mouseleave)="unhover('#imgCampus','/static/public/i/cards/Card-12-Campus-OFF.jpg')">
						<img class="card-img" id="imgCampus" src="{{assetsUrl}}/public/i/cards/Card-12-Campus-OFF.jpg" alt="AOD-campus">
						<div *ngIf="hovers[11].hover" class="card-img-overlay" style="text-align: center;">
							<h2>AOD Campus</h2>
							<p>Contenido ofrecido para que aprendas y te formes a través de nuestros materiales y tutoriales.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkToolsDevelopers}}" (mouseenter)="hover('#imgDesa', '/static/public/i/cards/Card-13-Desarrolladores-ON.jpg')"
					 (mouseleave)="unhover('#imgDesa', '/static/public/i/cards/Card-13-Desarrolladores-OFF.jpg')">
						<img class="card-img" id="imgDesa" src="{{assetsUrl}}/public/i/cards/Card-13-Desarrolladores-OFF.jpg" alt="desarrolladores">
						<div *ngIf="hovers[12].hover" class="card-img-overlay" style="text-align: center;">
							<h2>Desarrolladores</h2>
							<p>Estándares, interoperabilidad, estructuras y herramientas en las que se sustenta Aragón Open Data.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" routerLink="/{{routerLinkToolsApis}}" (mouseenter)="hover('#imgAPIs', '/static/public/i/cards/Card-14-APIs-ON.jpg')"
					 (mouseleave)="unhover('#imgAPIs', '/static/public/i/cards/Card-14-APIs-OFF.jpg')">
						<img class="card-img" id="imgAPIs" src="{{assetsUrl}}/public/i/cards/Card-14-APIs-OFF.jpg" alt="APIs">
						<div *ngIf="hovers[13].hover" class="card-img-overlay" style="text-align: center;">
							<h2>APIs</h2>
							<p>Tienes APIs para la búsqueda de datos, para AragoPedia y para el servicio Open Social Data.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkSparql)" (click)="goToUrl(routerLinkSparql)" (mouseenter)="hover('#imgSPARQL','/static/public/i/cards/Card-15-SPARQL-ON.jpg')"
					 (mouseleave)="unhover('#imgSPARQL','/static/public/i/cards/Card-15-SPARQL-OFF.jpg')">
						<img class="card-img" id="imgSPARQL" src="{{assetsUrl}}/public/i/cards/Card-15-SPARQL-OFF.jpg" alt="SPARQL">
						<div *ngIf="hovers[14].hover" class="card-img-overlay" style="text-align: center;">
							<h2>SPARQL</h2>
							<p>Obtén datos e información de la Aragopedia a través de este punto de consulta.</p>
						</div>
					</div>
				</div>
				<div class="ui-sm-12 ui-md-6 ui-lg-4" style="cursor: pointer;">
					<div tabindex="{{openedMenu ? -1 : 0}}" class="card card-inverse" (keydown.enter)="goToUrl(routerLinkToolsGithub)" (click)="goToUrl(routerLinkToolsGithub)" (mouseenter)="hover('#imgGit', '/static/public/i/cards/Card-16-GITHUB-ON.jpg')"
					 (mouseleave)="unhover('#imgGit', '/static/public/i/cards/Card-16-GITHUB-OFF.jpg')">
						<img class="card-img" id="imgGit" src="{{assetsUrl}}/public/i/cards/Card-16-GITHUB-OFF.jpg" alt="github">
						<div *ngIf="hovers[15].hover" class="card-img-overlay" style="text-align: center;">
							<h2>GITHUB</h2>
							<p>Código y fuentes usadas para desarrollar Aragón Open Data lo tienes disponible en nuestro perfil de GitHub.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="btn"></div>
		</div>
	</div>
	<div style="clear:both;"></div>
</div>