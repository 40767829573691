<div class="headerSeparator"></div>
<div class="pagina">
	<h1>OPEN ANALYTICS DATA</h1>
	<div>
		Open Analytics Data ofrece algunas estadísticas de uso de los portales más importantes del Gobierno de Aragón <b>del año en curso</b>. Este servicio
		ofrece de manera pública los datos más útiles en cuanto a la interacción de la ciudadanía con las páginas webs institucionales
		del Gobierno de Aragón y aporta de manera sencilla su uso, acceso e impacto, además también estos datos están disponibles
		mediante todo el potencial de los datos abiertos.
	</div>
</div>
<div class="iframeAnalytics">
	<iframe id="sevendays" [src]="sanitizer.bypassSecurityTrustResourceUrl(sevenurl)" marginwidth=0 marginheight=0 frameborder=0 scrolling="no"></iframe>
	<div class="btn-group float-left">
		<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
		 aria-expanded="false">
			{{ currentPortal.url }}
			<span class="caret"></span>
		</button>
		<ul class="dropdown-menu scrollable-menu" role="menu">
			<li>
				<button class="dropdown-item" (click) = "changePortal(allPortal)" >{{ allPortal.portal_name }}</button>
			</li>
			<div class="dropdown-divider"></div>
			<li *ngFor="let portal of portales">
				<button class="dropdown-item" (click) = "changePortal(portal)">{{ portal.portal_name }}</button>
			</li>
		</ul>
	</div>
	<div class="btn-group float-right">
		<button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
		 aria-expanded="false">
		 	{{ currentDay.name }}
			<span class="caret"></span>
		</button>
		<ul class="dropdown-menu" role="menu">
			<li *ngFor="let day of days">
				<button class="dropdown-item" (click)="changeTime(day)">{{ day.name }}</button>
			</li>
		</ul>
	</div>
	<iframe id="analytics" [src]="sanitizer.bypassSecurityTrustResourceUrl(globalurl)" marginwidth=0 marginheight=0 frameborder=0 scrolling="no"></iframe>
	<div class="pagina">
		<span class="panel-export-title">
			Descarga de datos abiertos
		</span>
		<div class="export">
			<ul>
				<li>
					Total visitas por usuario único (Geolocalización):
					<a [attr.href]="exportCountries('csv')" target="_blank">CSV</a>
					<a [attr.href]="exportCountries('json')" target="_blank">JSON</a>
				</li>
				<li>
					Páginas más vistas (primer acceso):
					<a [attr.href]="exportPages('csv')" target="_blank">CSV</a>
					<a [attr.href]="exportPages('json')" target="_blank">JSON</a>
				</li>
				<li>
					Navegadores utilizados (primer acceso):
					<a [attr.href]="exportBrowsers('csv')" target="_blank">CSV</a>
					<a [attr.href]="exportBrowsers('json')" target="_blank">JSON</a>
				</li>
				<li>
					<a class="stats" href="{{aodBaseUrl}}/{{routerLinkDataCatalogDataset}}/estadisticas-de-uso-de-los-portales-y-webs-mas-importantes-del-gobierno-de-aragon" target="_blank">Estadísticas de años anteriores</a>
				</li>
			</ul>
		</div>
	</div>
</div>
