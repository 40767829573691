<div class="container">
	<div class="login-box">
		<div class="login-logo">
			<a id="topElement" routerLink="/" tabindex="{{openedMenu ? -1 : 0}}" routerLinkActive="active">
		  <img id="#logo" src="{{assetsUrl}}/public/header/images/AOD-Logo.png" alt="logo-aragon-open-data" style="width: 80%;">
		</a>
		</div>
		<!-- /.login-logo -->
		<div class="login-box-body">
			<form (ngSubmit)="f.form.valid && login()" #f="ngForm" method="post" accept-charset="utf-8" novalidate>
				<div class="form-group has-feedback" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
					<input tabindex="{{openedMenu ? -1 : 0}}" type="text" placeholder="Nombre de usuario" class="form-control" id="login" maxlength="80" size="30" name="username" [(ngModel)]="model.username" #username="ngModel">
					<i class="fas fa-user form-control-feedback" aria-label="username"></i>
				</div>
				<div class="form-group has-feedback" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
					<input tabindex="{{openedMenu ? -1 : 0}}" type="password" placeholder="Contraseña" class="form-control" id="password" name="password" [(ngModel)]="model.password" #password="ngModel" required size="30">
					<i class="fas fa-lock form-control-feedback" aria-label="password"></i>
				</div>
				<br/>
				<div class="form-group">
					<button tabindex="{{openedMenu ? -1 : 0}}" [disabled]="loading" class="btn btn-primary btn-block btn-flat">Acceder</button>
					<img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
				</div>
				<div *ngIf="error" class="alert alert-danger">{{error}}</div>
			</form>
		</div>
	</div>
	<div class="text">
		<p tabindex="{{openedMenu ? -1 : 0}}">Si has olvidado tu usuario o contraseña escríbenos un email a <a tabindex="{{openedMenu ? -1 : 0}}" href="mailto:opendata@aragon.es" title="opendata@aragon.es">opendata@aragon.es</a> y nos pondremos en contacto contigo</p>
		<br/>
		<p tabindex="{{openedMenu ? -1 : 0}}">Si eres una organización, departamento, fundación, etc y quieres participar con tus datos en Aragón Open Data escríbenos a <a tabindex="{{openedMenu ? -1 : 0}}" href="mailto:opendata@aragon.es" title="opendata@aragon.es">opendata@aragon.es</a> y nos pondremos en contacto contigo</p>
	</div>
</div>