<div class="container">
	<div class="dtHeader row">
		<h1 tabindex="{{openedMenu ? -1 : 0}}" class="globaltitle col-12 col-md-10">TEMAS</h1>
		<div class="social col-12 col-md-2">
			<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkFacebookShare}}" target="_blank" title="Compartir en Facebook">
				<img src="{{assetsUrl}}/public/contenido-general/social/facebookIcon.png" alt="compartir-en-Facebook">
			</a>
			<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkTwitterShare}}" target="_blank" title="Compartir en Twitter">
				<img src="{{assetsUrl}}/public/contenido-general/social/twitterIcon.png" alt="compartir-en-Twitter">
			</a>
		</div>
	</div>
	<div class="container" *ngIf="errorTitle">
		<div class="alert alert-info" role="alert">
			<div class="row">
				<div class="col-xs-3">
					<i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
				</div> 
				<div class="col-xs-9">
					<h1>{{errorTitle}}</h1>
					<h3>{{errorMessage}}</h3>
					Por favor, repita la operación y si el error persiste
					<a class="mail" href="mailto:{{aodMail}}">
						<h3>contacte con el Administrador <i class="far fa-envelope"></i></h3>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="ui-g">
		<div class="ui-sm-12 ui-md-6 ui-lg-4 tarjetaContainer" *ngFor="let topic of topics; let i = index" (mouseenter)="setHover(topic.title, i)" (mouseleave)="unsetHover(topic.title, i)">
			<div tabindex="{{openedMenu ? -1 : 0}}" class="card" routerLink="/{{routerLinkDataTopics}}/{{topic.name}}" ng-init="selectedTopic = topic.name" (click)="setTopic(topic)" (keydown.enter)="navigate(topic.name); setTopic(topic);">
				<img class="card-img-top" src="{{topic.image_url}}" alt="{{topic.title | uppercase}}" width="fit-content" height="fit-content">
				<div class="card-block">
					<p>{{topic.title}}</p>
				</div>
				<div *ngIf="hovers[i].hover" class="detail">
					<h6 class="topicTitle">{{topic.title | uppercase}}</h6>
					<p class="description">{{topic.description}}</p>
					<div class="numDatasets">
						<b>{{topic.package_count}} conjuntos de datos publicados</b>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>