<!-- Page Content -->
<div class="container-fluid marginsup">
  <div class="row">
    <div class="col-md-1"></div>
    <div class=" col-md-10">
      <h1 tabindex="{{openedMenu ? -1 : 0}}" class="firstTitle">AOD CAMPUS</h1>

    </div>
  </div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class=" col-md-10">
      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="">{{content?.event_name}}</h2>
      <p tabindex="{{openedMenu ? -1 : 0}}" class="">{{content?.description}}</p>
      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="" style="font-size: 1.1rem;text-align: justify;">Etiquetas y palabras clave</h2>
      <div class="row" style="padding:10px">
        <span tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let top of topics" class="tags">{{top.name}}</span>
      </div>
      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="" style="font-size: 1.1rem;text-align: justify;">Ponente</h2>
      <p tabindex="{{openedMenu ? -1 : 0}}" class="" *ngFor="let speaker of speakers">
        {{speaker?.name}}
      </p>

      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="" style="font-size: 1.1rem;text-align: justify;">{{content?.platform}}</h2>
      <img tabindex="{{openedMenu ? -1 : 0}}" *ngIf="content.platform == contentPlatformAragonOpenData" class="img-fluid" src='{{contentAssetsBaseURL}}{{content?.url}}'>

      <div *ngIf="content.platform == contentPlatformLink">
        <a tabindex="{{openedMenu ? -1 : 0}}" href="{{content.url}}">
          <div class="divcurso">Curso de iniciación a los datos abiertos</div>
        </a>
      </div>

      <div *ngIf="content.platform == contentPlatformBostOcksOrg" class="col-md-8 embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]='sanitizer.bypassSecurityTrustResourceUrl(content?.url)'></iframe>
      </div>

      <div tabindex="{{openedMenu ? -1 : 0}}" *ngIf="content.platform == contentPlatformGitHub" class="col-md-8 embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]='sanitizer.bypassSecurityTrustResourceUrl(content?.url)'></iframe>
      </div>

      <div *ngIf="content.platform == contentPlatformPodcast" class=" col-md-8">
        <audio tabindex="{{openedMenu ? -1 : 0}}" src="{{content?.url}}" title="{{content?.event_name}}" preload="auto" controls="controls"></audio>
      </div>

      <div tabindex="{{openedMenu ? -1 : 0}}" *ngIf="content.platform == contentPlatformSlideShare" class="col-md-8 embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]='sanitizer.bypassSecurityTrustResourceUrl(content?.url)'></iframe>
      </div>

      <div tabindex="{{openedMenu ? -1 : 0}}" *ngIf="content.platform == contentPlatformVimeo" class="col-md-8 embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]='sanitizer.bypassSecurityTrustResourceUrl(content?.url)'></iframe>
      </div>

      <div *ngIf="content.platform == contentPlatformYoutube" class="col-md-8 embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]='sanitizer.bypassSecurityTrustResourceUrl(content?.url)'></iframe>
      </div>

      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="">EVENTO</h2>
      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="" style="font-size: 1.5rem;text-align: justify;">{{event?.name}}</h2>
      <p tabindex="{{openedMenu ? -1 : 0}}" class="">{{event?.description}}</p>
      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="" style="font-size: 1.1rem;text-align: justify;">Fecha</h2>
      <p tabindex="{{openedMenu ? -1 : 0}}" class="">{{event?.date | date: 'dd/MM/yyyy'}}</p>
      <h2 tabindex="{{openedMenu ? -1 : 0}}" class="" style="font-size: 1.1rem;text-align: justify;">Lugar</h2>
      <p tabindex="{{openedMenu ? -1 : 0}}" class="">{{event?.site}}</p>


    </div>

  </div>

  <!-- /.row -->

</div>
<!-- /.container -->