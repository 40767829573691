import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-datacenter',
	templateUrl: './datacenter.component.html',
	styleUrls: ['./datacenter.component.css']
})
export class DatacenterComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
