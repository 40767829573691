<div class="container">
	<div class="dtHeader row">
		<h1 tabindex="{{openedMenu ? -1 : 0}}" class="globaltitle col-12 col-md-10">PUBLICADORES DE ARAGÓN OPEN DATA</h1>
		<div class="social col-12 col-md-2">
			<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkFacebookShare}}" target="_blank" title="Compartir en Facebook">
				<img src="{{assetsUrl}}/public/contenido-general/social/facebookIcon.png" alt="compartir-en-Facebook">
			</a>
			<a tabindex="{{openedMenu ? -1 : 0}}" href="{{routerLinkTwitterShare}}" target="_blank" title="Compartir en Twitter">
				<img src="{{assetsUrl}}/public/contenido-general/social/twitterIcon.png" alt="compartir-en-Twitter">
			</a>
		</div>
	</div>
	<div class="container" *ngIf="errorTitle">
		<div class="alert alert-info" role="alert">
			<div class="row">
				<div class="col-xs-3">
					<i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
				</div> 
				<div class="col-xs-9">
					<h1>{{errorTitle}}</h1>
					<h2>{{errorMessage}}</h2>
					
						<a class="mail" href="mailto:opendata@aragon.es">
						   <h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
						</a>
					
				</div>
			</div>
		</div>
	</div>
	<p-dropdown tabindex="{{openedMenu ? -1 : 0}}" *ngIf="!errorTitle" [options]="views" [(ngModel)]="selectedView"></p-dropdown>
	<div *ngIf="selectedView" class="ui-g">
		<div class="ui-sm-12 ui-md-6 ui-lg-4" *ngFor="let org of orgs; let i = index" (mouseenter)="setHover(org.title, i)" (mouseleave)="unsetHover(org.title, i)">
			<div role="button" tabindex="{{openedMenu ? -1 : 0}}" class="card" routerLink="/{{routerLinkDataOrganizations}}/{{org.name}}" (click)="showOrg(org)" (keydown.enter)="navigate(org.name); showOrg(org);">
				<img class="card-img-top" src="{{org.image_url}}" width="297"/>
				<div class="card-block" style="position: relative; font-size: 15px; margin: 10; padding-top: 5px; height: 60px; background-color: darkslategray; color: white;">
					<p class="tittle"> {{org.title}}</p>
				</div>
				<div *ngIf="hovers[i].hover" class="detail bg-faded" style="position: absolute; width: 100%; height: 100%; padding: 10px; background-color: rgba(235,241,244, 0.95); opacity: 80%; ">
					<h6 class="topicTitle">{{org.title | uppercase}}</h6>
					<p class="description">{{org.description}}</p>
					<div class="numDatasets">
						<b>{{org.package_count}} conjuntos de datos publicados</b>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="!selectedView" class="tabla"><br>
		<p-dataTable [value]="orgs" (onRowSelect)="showOrg(rowData)">
			<p-column field="orgName" header="HAY {{orgs.length}} ORGANIZACIONES" styleClass="text-left">
				<ng-template pTemplate="body" let-org="rowData" let-i="rowIndex">
					<a tabindex="{{openedMenu ? -1 : 0}}" routerLink='/{{routerLinkDataOrganizations}}/{{org.name}}' (click)="showOrg(org)">
					  {{org.title}}
				  </a>
				</ng-template>
			</p-column>
			<p-column field="numDatasets" [style]="{'width':'150px'}" styleClass="text-center" header="Nº DATASETS">
				<ng-template pTemplate="body" let-org="rowData">
					{{org.package_count}}
				</ng-template>
			</p-column>
		</p-dataTable>
	</div>
</div>