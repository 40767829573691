import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-organizations-admin',
	templateUrl: './organizations-admin.component.html',
	styleUrls: ['./organizations-admin.component.css']
})
export class OrganizationsAdminComponent implements OnInit {
	
	constructor() { }

	ngOnInit() {
	}

}
	