<!-- Page Content -->
<div class="container">
	<div class="row">
		<div class=" col-md-12">
			<h1>Editar conjunto de datos</h1>
			<h5>{{dataset.title}}</h5>
		</div>
	</div>

	<!-- Dialogs -->
	<p-dialog header="¿Está seguro de que desea salir?" [(visible)]="displayDeleteDialog">
		<p>Se va a proceder a volver al listado. Todos los cambios que no se hayan guardado se perderan.
			<b>{{datasetTitleDelete}}</b>
		</p>
		<p>¿Desea continuar?</p>
		<p-footer>
			<button type="button" pButton icon="fas fa-check" (click)="cancelDataset()" label="Sí"></button>
			<button type="button" pButton icon="fas fa-times" (click)="undoCancelDataset()" label="No"></button>
		</p-footer>
	</p-dialog>

	<p-dialog header="¿Desea eliminar el dataset y salir?" [(visible)]="displayAutoSavedDeleteDialog">
			<p>El dataset ha sido guardado automáticamente. Se va a proceder a volver al listado. Puede eliminar el datset o mantenerlo sin guardar los cambios.
				<b>{{datasetTitleDelete}}</b>
			</p>
			<p>¿Qué desea hacer?</p>
			<p-footer>
				<button type="button" pButton icon="fas fa-check" (click)="deleteDatasetAndExit()" label="Eliminar y salir"></button>
				<button type="button" pButton icon="fas fa-times" (click)="cancelDataset()" label="Mantener y salir"></button>
			</p-footer>
		</p-dialog>

	<p-dialog [(visible)]="resourceOperation" [modal]="true">
		<p>
			Espere mientras la operación con el recurso siga activa.
		</p>
	</p-dialog>

	<div class="modal fade modalAddResource" tabindex="-1" id="modalAddResource" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Añadir Recurso</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form class="col-12 content-fluid pt-1">
					<div class="form-group">
						<p-messages closable="true" label="Creacuón de Recurso" [(value)]="msgs"></p-messages>
					</div>
					<div class="form-group row">
						<label for="inputUrl" class="col-sm-2 col-form-label">Acceso al recurso:</label>
						<div class="col-sm-10">
							<select class="form-control" name="accesTypeDropdown" [(ngModel)]="newResourceAccessType" placeholder="Seleccione el tipo de acceso">
								<option *ngFor="let accessType of newResourceAccessTypes" [ngValue]="accessType.value">{{accessType.label}}</option>
							</select>
						</div>
					</div>
					<div *ngIf="newResourceAccessType == newResourceAccessTypePublicFile" class="form-group row">
						<label for="inputUrl" class="col-sm-2 col-form-label">Url:</label>
						<div class="col-sm-10">
							<input type="text" class="form-control" name="url" id="inputUrl" [(ngModel)]="newResourceUrl" placeholder="http://example.com/external-data.csv">
						</div>
					</div>
					<div *ngIf="newResourceAccessType == newResourceAccessTypeDatabaseView" class="form-group row">
						<label for="inputFile" class="col-sm-2 col-form-label">Vista</label>
						<div class="col-sm-10">
							<select class="form-control" name="coreViewsDropdown"  placeholder="Seleccione vista" [(ngModel)]="selectedCoreView">
								<option *ngFor="let coreView of coreViewsSelect" [ngValue]="coreView.value">{{coreView.label}}</option>
							</select>
						</div>
					</div>
					<div *ngIf="newResourceAccessType == newResourceAccessTypeFile" class="form-group row">
						<label for="inputFile" class="col-sm-2 col-form-label">Fichero</label>
						<div class="col-sm-10">
							<input type="file" class="form-control" (change)="fileChange($event)" placeholder="Subir fichero">
						</div>
					</div>
					
					<div class="form-group row">
						<label for="inputName" class="col-sm-2 col-form-label">Nombre:</label>
						<div class="col-sm-10">
							<input type="text" class="form-control" id="name" required [(ngModel)]="newResourceName" name="name" placeholder="Nombre">
						</div>
					</div>
					<div class="form-group row">
						<label for="inputDescription" class="col-sm-2 col-form-label">Descripción:</label>
						<div class="col-sm-10">
							<input type="area" class="form-control" name="description" id="inputDescription" [(ngModel)]="newResourceDescription" placeholder="Algunas notas útiles sobre los datos">
						</div>
					</div>
					<div class="form-group row" *ngIf="newResourceAccessType != newResourceAccessTypeDatabaseView">
						<label for="inputName" class="col-sm-2 col-form-label">Formato:</label>
						<div class="col-sm-10">
							<select class="form-control" name="format" [(ngModel)]="newResourceFormat">
								<option *ngFor="let format of formatos" [ngValue]="format.value">{{format.label}}</option>
							</select>
						</div>
					</div>
					<div class="form-group col-12">
						<div class="row justify-content-end">
							<div class="btn-group" role="group" aria-label="Action Buttons">
								<button type="submit" class="btn btn-primary" (click)="addResource()">
									<i class="fas fa-plus"></i> Añadir</button>
								<button type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Close">
										<i class="fas fa-times"></i> Cancelar
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="modal fade modalShowResource" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		<div *ngIf="resource != undefined" class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title">Información Recurso</h2>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form class="col-12 content-fluid">
					<div class="form-group row">
						<label for="inputUrl" class="col-sm-2 col-form-label">Url:</label>
						<div class="col-sm-10">
							<p>{{resource.url}}</p>
						</div>
					</div>
					<div class="form-group row">
						<label for="inputFile" class="col-sm-2 col-form-label">Tipo</label>
						<div class="col-sm-10">
							<p>{{resourceType}}</p>
						</div>
					</div>
					<div class="form-group row">
						<label for="inputName" class="col-sm-2 col-form-label">Nombre:</label>
						<div class="col-sm-10">
							<p>{{resource.name}}</p>
						</div>
					</div>
					<div class="form-group row">
						<label for="inputDescription" class="col-sm-2 col-form-label">Descripción:</label>
						<div class="col-sm-10">
							<p>{{resource.description}}</p>
						</div>
					</div>
					<div class="form-group row">
						<label for="inputName" class="col-sm-2 col-form-label">Formato:</label>
						<div class="col-sm-10">
							<p>{{resource.format}}</p>
						</div>
					</div>
					<div class="form-group col-12">
						<div class="row justify-content-end">
							<div class="btn-group" role="group" aria-label="Action Buttons">
								<button type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Close">Volver</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div class="modal fade modalEditResource" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		<div *ngIf="resource != undefined" class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title">Editar Recurso</h2>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form class="col-12 content-fluid pt-1">
					<div class="form-group row">
						<label for="inputUrl" class="col-sm-2 col-form-label">Url:</label>
						<div class="col-sm-10">
							<p *ngIf="resourceType == 'Fichero'">{{resource.url}}</p>
							<input *ngIf="resourceType != 'Fichero'" type="text" class="form-control" id="url" required [(ngModel)]="resource.url" name="url">
						</div>
					</div>
					<div class="form-group row">
						<label for="inputFile" class="col-sm-2 col-form-label">Tipo</label>
						<div class="col-sm-2" *ngIf="resourceType == 'Fichero'">
							<p>{{resourceType}}</p>
						</div>
						<div class="col-sm-10" *ngIf="resourceType != 'Fichero'">
							<p>{{resourceType}}</p>
						</div>
						<div *ngIf="resourceType == 'Fichero'" class="col-sm-8">
							<input type="file" class="form-control" (change)="fileChange($event)" placeholder="Subir fichero">
						</div>
					</div>
					<div class="form-group row">
						<label for="inputName" class="col-sm-2 col-form-label">Nombre:</label>
						<div class="col-sm-10">
							<input type="text" class="form-control" id="name" required [(ngModel)]="resource.name" name="name">
						</div>
					</div>
					<div class="form-group row">
						<label for="inputDescription" class="col-sm-2 col-form-label">Descripción:</label>
						<div class="col-sm-10">
							<input type="area" class="form-control" name="description" id="inputDescription" [(ngModel)]="resource.description" placeholder="Algunas notas útiles sobre los datos">
						</div>
					</div>
					<div class="form-group row">
						<label for="inputName" class="col-sm-2 col-form-label">Formato:</label>
						<div class="col-sm-10">
							<p>{{resource.format}}</p>
						</div>
					</div>
					<div class="form-group col-12">
						<div class="row justify-content-end">
							<div class="btn-group" role="group" aria-label="Action Buttons">
								<button type="submit" class="btn btn-primary" (click)="updateResource()" data-dismiss="modal" >
									<i class="fas fa-plus"></i> Actualizar</button>
								<button type="button" class="btn btn-danger" data-dismiss="modal" aria-label="Close">
									<i class="fas fa-times"></i> Cancelar
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="modal fade modalRemoveResource" tabindex="-1" id="modalRemoveResource" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h2 class="modal-title">Eliminar Recurso</h2>
					<button type="button" class="close" data-dismiss="modal" (click)="undoDeleteResource()" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<form class="col-12 content-fluid">
					<div class="form-group">
						<label class="col-form-label">¿Esta seguro que desea borrar el recurso?</label>
					</div>
					<div class="form-group col-12">
						<div class="row justify-content-end">
							<div class="btn-group" role="group" aria-label="Action Buttons">
								<button type="submit" class="btn btn-danger" (click)="deleteResource()">
									<i class="fas fa-trash-alt"></i> Eliminar</button>
								<button type="button" data-dismiss="modal" (click)="undoDeleteResource()" class="btn btn-primary">
									<i class="fas fa-times"></i> Cancelar</button>
							</div>
						</div>
					</div>
					
				</form>
			</div>
		</div>
	</div>

	<p-growl [(value)]="msgs"></p-growl>


	<!-- Nav tabs -->
	<div class="row">
		<div class=" col-md-12">
			<ul class="nav nav-tabs nav-fill" role="tablist">
				<li class="nav-item">
					<a class="nav-link active" [class.disabled]="!activeTab[0]" (click)="checkAndNextTab(0, false)" data-toggle="tab" href="#content_tab" role="tab">1º - Contenido *</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [class.disabled]="!activeTab[1]" (click)="checkAndNextTab(1, false)" data-toggle="tab" href="#context_tab" role="tab">2º - Contexto *</a>
				</li>
				<li class="nav-item">
					<a class="nav-link enabled" [class.disabled]="!activeTab[2]" (click)="checkAndNextTab(2, false)" data-toggle="tab" href="#structure_tab" role="tab">3º - Estructura *</a>
				</li>
				<li *ngIf="isIAESTDataset" class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#extra_iaest" role="tab">4º - Extras IAEST</a>
				</li>
			</ul>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="tab-content" style="padding-bottom: 5%;">

				<!-- Content Tab -->
				<div class="tab-pane active" id="content_tab" role="tabpanel">
					<div class="col-12" style="padding: 1%; text-align: justify;">
						<div style="margin-top: 2%;">
							<h3>Título *</h3>
							<p>
									Por favor, dale una denominación del conjunto de datos. El nombre que des al conjunto de datos es importante porque se convierte en su identificador.
							</p>
							<input class="col-12 form-control" type="text" (keyup)="createUrl()" placeholder="Título" [(ngModel)]="inputDatasetTitle">
						</div>
						<div style="margin-top: 2%;">
							<h3 style="margin-top: 2%;">URL</h3>
							<p>{{inputDatasetUrl}}</p>
						</div>
						<h3 style="margin-top: 2%;">Descripción *</h3>
						<p>
							La descripción es la primera aproximación de un usuario a tu conjunto de datos, así que se debería comenzar contando brevemente
							qué contiene el mismo. Si el conjunto de datos contiene informaciones parciales, limitaciones o deficiencias este
							es el lugar en el que puedes mencionarlas de forma que los usuarios puedan saber el alcance de la información. En
							algunos casos los usuarios ayudan a mejorar la información, así que no desaproveches la oportunidad de acercarles
							la realidad del dato.
						</p>
						<textarea class="col-12 form-control" (keyup)="checkTabCreationMode()" [(ngModel)]="inputDatasetDescription" rows="5" cols="150"></textarea>
						<h3 style="margin-top: 2%;">Temática *</h3>
						<p>
							Estos son los temas conforme a la Norma Técnica de Interoperabilidad: elige el que crea que se adapta mejor a la información que contiene tu conjunto de datos.
						</p>
						
						<select class="form-control" (change)="checkTabCreationMode()" [(ngModel)]="selectedTopic">
							<option *ngFor="let topSel of topicsSelect" [ngValue]="topSel.value">{{topSel.label}}</option>
						</select>
						
						<h3 style="margin-top: 2%;">Etiquetas</h3>
						<p>
							Por favor, introduce un listado de etiquetas que describan el contenido de tu conjunto de datos. Utiliza palabras comunes
							para describirlo. A poder ser utiliza palabras de las que te sugiere el formulario, ya que son palabras que provienen
							de EuroVoc (
							<a href="http://eurovoc.europa.eu/drupal/?q=es">http://eurovoc.europa.eu/drupal/?q=es</a>) y su uso mejora mucho la interoperabilidad del conjunto de datos.
						</p>
						<p>Escribe la primera letra en mayúscula y el resto en minúscula.</p>
						
						<div class="form-group">
							<label for="" class="col-2 col-form-label">Etiquetas</label>
							<div class="dropdown">
								<input type="text" class="form-control" data-toggle="dropdown" placeholder="Escriba la etiqueta" 
								(keyup)="search(tagInput.value)" (keyup.enter)="addNewTag(tagInput.value)" [(ngModel)]="tagValue" #tagInput>
								<div class="dropdown-menu" aria-labelledby="dropdownMenu2">
									<button *ngFor="let tag of datasetTags" (click)="addTag(tag);tagInput.value=''" class="dropdown-item" type="button">{{tag.display_name}}</button>
									<button *ngIf="datasetTags && datasetTags.length == 0" class="btn btn-primary m-3" (click)="addNewTag(tagInput.value);tagInput.value=''" type="button" >Añadir Etiqueta</button>
								</div>
							</div>
						</div>
						<div class="col-12">
							<h3>
								<span *ngFor="let tag of tags" class="badge badge-pill badge-secondary">{{tag.display_name}}
									<span class="fas fa-times" (click)="delTag(tag.display_name)"></span>
								</span>
							</h3>
						</div>
						
						<h3 style="margin-top: 2%;">Frecuencia de actualización</h3>
						<p>
							Por favor, indica la frecuencia con la que se actualiza la información del conjunto de datos:
						</p>
						<select class="form-control" [(ngModel)]="extraFrequency">
							<option *ngFor="let fre of freq" [ngValue]="fre.value">{{fre.label}}</option>
						</select>

						<h3 style="margin-top: 2%;">Cobertura temporal del conjunto de datos</h3>
						<p>
							Por favor, indica en este campo el periodo temporal del que contiene información tu conjunto de datos. Si tu conjunto de
							datos está vivo y se va refrescando a medida que pasa el tiempo, deja seleccionada la casilla de selección que aparece
							en la parte de "hasta…". En ese caso entenderemos que tu conjunto de datos contiene información desde la fecha que
							indiques hasta la actualidad.
						</p>
						<div class="row">
							<div class="col-5">
								<div class="form-group">
									<label>Este conjunto de datos contiene información desde:</label>
									<div class="input-group">
										<input type="text" class="form-control" [(ngModel)]="extraTemporalFrom" placeholder="2018-01-01">
									</div>
								</div>
							</div>
							<div class="col-5">
								<div class="form-group">
									<label>hasta:</label>
									<div class="input-group">
										<input type="text" class="form-control" [(ngModel)]="extraTemporalUntil" placeholder="2018-12-31">
									</div>
								</div>
							</div>
						</div>
						<fieldset class="form-group">
							<h3 style="margin-top: 2%;">Cobertura geográfica *</h3>
							<p>Por favor introduce el ámbito geográfico del que tu conjunto de datos contiene información. Únicamente es posible
								escribir dentro de una de las opciones que se muestran y además hay que hacerlo con uno de los territorios que se
								da en los listados (salvo si se rellena el campo otro).
							</p>
							<div class="form-group row">
								<label class="form-check-label col-sm-2">
									<input type="radio" class="form-check-input" name="coberturaGeo" id="aragonRadio" [checked]="aragonRadioValue" (change)="changeRadioGeo('aragon')" > Aragón
								</label>
								<div class="col-sm-10">
								</div>
							</div>
							<div class="form-group row">
								<label class="form-check-label col-sm-2">
									<input type="radio" class="form-check-input" name="coberturaGeo" id="provinciaRadio" [checked]="provinciaRadioValue" (change)="changeRadioGeo('provincia')" > Provincia de:
								</label>
								<div class="col-sm-10">
									<input type="text" class="form-control" (change)="checkTabCreationMode()" [(ngModel)]="provinciaInput" placeholder="Provincia">
								</div>
							</div>
							<div class="form-group row">
								<label class="form-check-label col-sm-2">
									<input type="radio" class="form-check-input" name="coberturaGeo" id="comarcaRadio" [checked]="comarcaRadioValue" (change)="changeRadioGeo('comarca')" > Comarca de:
								</label>
								<div class="col-sm-10">
									<input type="text" class="form-control" (change)="checkTabCreationMode()" [(ngModel)]="comarcaInput" placeholder="Comarca">
								</div>
							</div>
							<div class="form-group row">
								<label class="form-check-label col-sm-2">
									<input type="radio" class="form-check-input" name="coberturaGeo" id="municipioRadio" [checked]="municipioRadioValue" (change)="changeRadioGeo('municipio')" > Municipio de:
								</label>
								<div class="col-sm-10">
									<input type="text" class="form-control" (change)="checkTabCreationMode()" [(ngModel)]="municipioInput" placeholder="Municipio">
								</div>
							</div>
							<div class="form-group row">
								<label class="form-check-label col-sm-2">
									<input type="radio" class="form-check-input" name="coberturaGeo" id="otroRadio" [checked]="otherRadioValue" (change)="changeRadioGeo('otro')" > Otro:
								</label>
								<div class="col-sm-10">
									<input type="text" class="form-control" id="otherInputGeo" (change)="checkTabCreationMode()" [(ngModel)]="otherInputGeo" placeholder="Otro">
								</div>
							</div>
						</fieldset>

						<h3 style="margin-top: 2%;">Idiomas</h3>
						<p>
							Por favor, selecciona el idioma o idiomas en los que existe información en tu conjunto de datos.
						</p>
						<div class="col-12">
							<div class="form-check">
								<label class="checkboxLanguage col-12">
									<input type="checkbox" aria-label="Checkbox for following text input" [checked]="checkLangBoolEs" (change)="changeCheckBoxLang('Español')"> Español
								</label>
								<label class="checkboxLanguage col-12">
									<input type="checkbox" aria-label="Checkbox for following text input" [checked]="checkLangBoolEn" (change)="changeCheckBoxLang('Inglés')"> Inglés
								</label>
								<label class="checkboxLanguage col-12">
									<input type="checkbox" aria-label="Checkbox for following text input" [checked]="checkLangBoolFr" (change)="changeCheckBoxLang('Francés')"> Francés
								</label>
								<label class="checkboxLanguage col-12">
									<input type="checkbox" aria-label="Checkbox for following text input" [checked]="checkLangBoolArg_Lng" (change)="changeCheckBoxLang('Lenguas aragonesas')"> Lenguas aragonesas
								</label>
								<label class="checkboxLanguage col-12">
									<input type="checkbox" aria-label="Checkbox for following text input" [checked]="checkLangBoolOther" (change)="changeCheckBoxLang('Otro')"> Otro
								</label>
							</div>
							<input type="text" class="form-control" [(ngModel)]="checkLangOther" placeholder="Otro" #otherLangInput>
						</div>

						<h3 style="margin-top: 2%;">Nivel de detalle</h3>
						<p>
							Este campo debe indicar el menor nivel de detalle al que se refiere el conjunto de datos. El menor nivel de detalle se puede
							referir a diferentes "dimensiones" del conjunto de datos si es que este las tuviera, por lo que en este campo se admite
							más de una palabra.
						</p>
						<p>
							Por ejemplo el menor nivel de detalle dentro de una "dimensión" temporal podrían ser segundos, minutos, horas…; en la "dimensión"
							espacial podría ser calle, código postal, municipio…; en la "dimensión" de entidades podría ser persona, escuela, parque
							natural…
						</p>
						<p>
							Escribe la primera letra de cada nivel de detalle en mayúscula y el resto en minúscula.
						</p>
						<input type="text" class="form-control col-6" [(ngModel)]="extraGranularity" placeholder="Nivel de detalle">
						
						<div class="tab-pane" id="tmp" role="tabpanel" style="display: none">
							<h3 style="margin-top: 2%;">Fecha de la primera publicación del conjunto de datos</h3>
							<p>
								Fecha de primera publicación del conjunto de datos (esta información la rellena automáticamente el sistema).
							</p>
							<b>{{dataset?.metadata_created | date: 'dd/MM/yyyy'}}</b>
							
							<h3 style="margin-top: 2%;">Fecha de la última modificación del conjunto de datos</h3>
							<p>
								Fecha de última modificación del conjunto de datos (esta información la rellena automáticamente el sistema).
							</p>
							<b>{{dataset?.metadata_modified | date: 'dd/MM/yyyy'}}</b>
		
						</div>

					</div>
				</div>

				<!-- Context Tab -->
				<div class="tab-pane" id="context_tab" role="tabpanel">
					<div class="col-12" style="padding: 1%; text-align: justify;">
				
						<h3 style="margin-top: 2%;">Publicador *</h3>
						<p>
							Seleccione una organización entre las disponibles:
						</p>
						<select class="form-control" (change)="checkTab2CreationMode()" [(ngModel)]="selectedOrg">
							<option *ngFor="let orgSel of orgsSelect" [ngValue]="orgSel.value">{{orgSel.label}}</option>
						</select>
						<p>
							En esta sección se muestran la organización encargada de la publicación de este conjunto de datos tal y cómo se facilitarán
							a los usuarios.
						</p>
						<p>
							Esta información se ha confeccionado con los datos aportados al dar de alta la organización publicadora, para modificarla
							utiliza la pizarra de administración de tu organización.
						</p>
						<h3 style="margin-top: 2%;">Calidad del dato</h3>
						<p>
							Si has utilizado alguna metodología para controlar la calidad de los datos este es el lugar para explicarla, por ejemplo normas ISO, normas concretas… etc
						</p>
						<textarea class="col-12" rows="2" [(ngModel)]="extraDataQuality" placeholder="Calidad del dato" cols="150" pInputTextarea></textarea>
						<p>
							Si tus metodologías de control de calidad están explicadas en un enlace externo copia aquí la dirección o direcciones.
						</p>
						<div class="col-12" *ngFor="let urlCalidad of urlsCalidad; let i of index">
							<input type="text" size="50" placeholder="URL de acceso" pInputText>
							<button type="button" (click)="quitUrl()" pButton label="Quitar"></button>
						</div>
						<ul class="list-group">
							<li class="list-group-item justify-content-between col-12" *ngFor="let urlDic of extraDataQualityURL; let i of index">
								<div class="form-group row">
									<label for="inputFile" class="col-sm-1 col-form-label">URL :</label>
									<div class="col-sm-9">
										<p>{{urlDic}}</p>
									</div>
									<button class="col-sm-2 btn btn-danger" type="button" (click)="removeUrl('quality', urlDic)">Quitar
										<i class="fas fa-times"></i>
									</button>
								</div>
							</li>
							<li class="list-group-item active">
								<div class="form-group row">
									<label for="inputFile" class="col-sm-1 col-form-label">URL:</label>
									<div class="col-sm-9">
										<input type="text" class="form-control" placeholder="Introduzca URL" #inputUrlQuality>
									</div>
									<button type="button" (click)="addUrl('quality', inputUrlQuality.value);inputUrlQuality.value=''" class="col-sm-2 btn btn-primary">Añadir
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</li>
						</ul>
						<h3 style="margin-top: 2%;">Diccionario de datos</h3>
						<p>
							En este campo puedes comentar y añadir cualquier clase de información que ayude a la comprensión del conjunto de datos.
						</p>
						<textarea class="col-12" rows="2" [(ngModel)]="extraDictionary" placeholder="Diccionario de datos" cols="150" pInputTextarea></textarea>
						<p>
							Si la información de interés se encuentra publicada en un enlace externo copia aquí la dirección o direcciones.
						</p>
						<ul class="list-group">
							<li class="list-group-item justify-content-between col-12" *ngFor="let urlDic of extraDictionaryURL; let i of index">
								<div class="form-group row">
									<label for="inputFile" class="col-sm-1 col-form-label">URL :</label>
									<div class="col-sm-9">
										<p>{{urlDic}}</p>
									</div>
									<button class="col-sm-2 btn btn-danger" type="button" (click)="removeUrl('dictionary', urlDic)">Quitar
										<i class="fas fa-times"></i>
									</button>
								</div>
							</li>
							<li class="list-group-item active">
								<div class="form-group row">
									<label for="inputFile" class="col-sm-1 col-form-label">URL:</label>
									<div class="col-sm-9">
										<input type="text" class="form-control" placeholder="Introduzca URL" #inputUrlDictionary>
									</div>
									<button type="button" (click)="addUrl('dictionary', inputUrlDictionary.value);inputUrlDictionary.value=''" class="col-sm-2 btn btn-primary">Añadir
										<i class="fas fa-plus"></i>
									</button>
								</div>
							</li>
						</ul>

						<h3 style="margin-top: 2%;">Licencia</h3>
						<p>
							Para promover la máxima reutilización, en Aragón Open Data establecemos por defecto una licencia Creative Commons Attribution
							4.0 según se expone en la sección "Términos de uso" (
							<a href="http://opendata.aragon.es/terminos">http://opendata.aragon.es/terminos</a>). Si tu conjunto de datos por alguna razón legal, contractual o de otro tipo
							no puede ser ofrecido con esta licencia escríbenos a opendata@aragon.es y la modificaremos.
						</p>
	
						<h3 style="margin-top: 2%;">Visibilidad *</h3>
						<p>Seleccione el estado del dataset. Por defecto Público</p>
						<select class="form-control" [(ngModel)]="selectedState">
							<option value="public">Público</option>
							<option value="private">Privado</option>
						</select>
					</div>
				</div>
				
				<!-- Structure tab -->
				<div class="tab-pane" id="structure_tab" role="tabpanel">
						<h3 style="margin-top: 2%;">Ficheros de datos *</h3>
						<ul>
							<li>
								Acceso al recurso: Seleccione la forma de cómo se va a obtener el recurso.
							</li>
							<li>
								Nombre: Introduce un nombre descriptivo puedes utilizar el mismo nombre que para el conjunto de datos.
							</li>

							<li>
								Descripción: Si deseas dar más detalle sobre los datos
							</li>
							<li>
								Formato: Introduce el formato del recurso que contiene la información Los formatos más aconsejables son CSV, XML, JSON y RDF. No introduzca PDF.
							</li>
						</ul>
						<p-dataTable [loading]="false" [value]="resources" [responsive]="false" emptyMessage="No se han encontrado recursos." [rows]="10" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
							<p-column field="name" header="Nombre" sortable="custom" [sortable]="false"></p-column>
							<p-column field="resource_type" header="Tipo" sortable="custom" [sortable]="false">
								<ng-template pTemplate="body" let-resource="rowData">
									<div *ngIf="resource.resource_type == 'view'"><i class="fas fa-database"></i> Vista BBDD</div>
									<div *ngIf="resource.resource_type == 'file'"><i class="fas fa-file"></i> Fichero</div>
									<div *ngIf="resource.resource_type == 'url'"><i class="fas fa-external-link-alt"></i> Url Externa</div>
								</ng-template>
							</p-column>
							<p-column field="format" header="Formato" sortable="custom" [sortable]="false"></p-column>
							<p-column header="Edición" [style]="{'width':'14%'}">
								<ng-template pTemplate="body" let-resource="rowData">
									<button type="button" class="btn btn-primary" data-toggle="modal" (click)="openShowResource(resource)" data-target=".modalShowResource"><i class="fas fa-search"></i></button>
									<button type="button" class="btn btn-primary" data-toggle="modal" (click)="openEditResource(resource)" data-target=".modalEditResource"><i class="fas fa-edit"></i></button>
									<button type="button" class="btn btn-primary" data-toggle="modal" (click)="openRemoveResource(resource)" data-target=".modalRemoveResource"><i class="fas fa-trash-alt"></i></button>
								</ng-template>
							</p-column>
							<p-footer>
								<div *ngIf="!previouslySaved" class="row">
									<div class="col-md-12">
										<p>Si añades el recurso, <b>el dataset se guardará automáticamente</b></p>
									</div>
								</div>
								<div class="ui-helper-clearfix" style="width:100%">
									<button type="button" class="btn btn-primary" data-toggle="modal" style="float:left" data-target=".modalAddResource" (click)="resetAddResourceModal()">
									<i class="fas fa-plus"></i> Añadir</button>
								</div>
							</p-footer>
							</p-dataTable>

						<!-- <div class="row">
							<div class="col-md-12">
								<p>Sube el fichero mapeo_ei2a.xlsm modificado:</p>
							</div>
						</div> -->
						<div class="row">
							<div class="col-md-12">
								<p style="margin-top: 2%">
										<b>Importante una vez que haya incorporado datos,</b> relacione sus datos con la Estructura de información interoperable de Aragón EI2A, para ello:
									</p>
									<ul>
										<li>
											1. Descargue el archivo excel <a href="https://opendata.aragon.es/public/documentos/mapeo_ei2a.xlsm" target="_blank">mapeo_ei2a.xlsm</a> y habilite su edición y contenido.
										</li>
										<li>
											2. Cumplimente en la fila inicial la cabecera con la denominación de los campos de sus datos y seleccione en las siguientes filas los elementos y entidades acordes del EI2A a los que correspondan. Tenga en cuenta en incluir solo los campos representativos de sus datos, evite códigos internos de bases de datos y para cualquier ayuda o petición contacte con <a href="mailto:opendata@aragon.es">opendata@aragon.es</a>
										</li>
										<li>
											3. Una vez cumplimentado y guardado, suba el fichero con la siguiente opción:  <input class="primary" type="file" #uploader (change)="uploadFileTmp($event)">
										</li>
									</ul>
							</div>
						</div>
						<!-- <div class="row">
							<div class="col-md-12">
								
							</div>
						</div> -->
						<div *ngIf="showMapLink" class="row" style="margin-top: 1%">
							<div class="col-md-12">
								<p>Descargar fichero de mapeo modificado:</p>
							</div>
						</div>
						<div *ngIf="showMapLink" class="row">
							<div class="col-md-12">
								<!--<button type="button" pButton icon="fas fa-trash-alt" (click)="deleteMapFile($event)" aria-hidden="true"></button>-->
								<button type="button" pButton icon="fas fa-download" (click)="downloadMapFile($event)" aria-hidden="true"></button> mapeo_ei2a.xlsm
							</div>
						</div>

				</div>

				<div *ngIf="isIAESTDataset" class="tab-pane" id="extra_iaest" role="tabpanel">
						<h3 style="margin-top: 2%;">Tema Estadístico</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTTemaEstadistico" placeholder="Tema Estadístico">
	
						<h3 style="margin-top: 2%;">Unidad Estadística</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTUnidadEstadistica" placeholder="Unidad Estadística">
	
						<h3 style="margin-top: 2%;">Población Estadística</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTPoblacionEstadistica" placeholder="Población Estadística">
	
						<h3 style="margin-top: 2%;">Unidad de medida</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTUnidadMedida" placeholder="Unidad de medida">
	
						<h3 style="margin-top: 2%;">Periodo base</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTPeriodoBase" placeholder="Periodo base">
	
						<h3 style="margin-top: 2%;">Tipo de operación</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTTipoOperacion" placeholder="Tipo de operación">
	
						<h3 style="margin-top: 2%;">Tipología de datos de origen</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTTipologiaDatosOrigen" placeholder="Tipología de datos de origen">
	
						<h3 style="margin-top: 2%;">IAEST Fuente</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTFuente" placeholder="IAEST Fuente">
	
						<h3 style="margin-top: 2%;">Tratamiento Estadístico</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTTratamientoEstadistico" placeholder="Tratamiento Estadístico">
	
						<h3 style="margin-top: 2%;">IAEST Legislación UE</h3>
						<input type="text" class="form-control" [(ngModel)]="extraIAESTLegislacionUE" placeholder="IAEST Legislación UE">
					</div>
				
			</div>
			<p>Los campos marcados con este asterisco * son obligatorios, recuerda rellenarlos</p>
			<div *ngIf="currentTab === 2" class="row justify-content-end pb-5">
				<div class="btn-group" role="group" aria-label="Action Buttons">
					<button id="saveAndFinishB" type="button" (click)="checkAndNextTab(currentTab, true)" class="btn btn-primary">Guardar</button>
					<button type="button" (click)="showCancelDialog(dataset.title,dataset.name)" class="btn btn-danger">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</div>