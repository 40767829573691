<p-growl [(value)]="organizationAdminMessages"></p-growl>
<p-confirmDialog acceptLabel="Sí">
</p-confirmDialog>
<div class="container" style="padding: 2%;">
   <!--If the user have permissions to delete-->
  <p-dataTable *ngIf="showEditButtons" [value]="orgs" [paginator]="true" [rows]="15" [responsive]="true" [globalFilter]="gb" [rowsPerPageOptions]="[5,10,15]" #dt>
    <p-column field="display_name" header="Nombre" [sortable]="true" [style]="{'width':'50%'}"></p-column>
    <p-column field="state" header="Estado" [sortable]="true" [style]="{'width':'20%'}">
      <ng-template pTemplate="body" let-org="rowData">
        <span *ngIf="org.state == 'active'"> Activo</span>
        <span *ngIf="org.state == 'deleted'"> Borrado</span>
        <span *ngIf="org.state != 'deleted' && org.state != 'active'"> {{org.state}}</span>
			</ng-template>
    </p-column>
    <p-column field="package_count" header="Nº datos" [sortable]="true" [style]="{'width':'18%'}"></p-column>
    <p-column header="Edición"  [style]="{'width':'12%'}">
      <ng-template pTemplate="body" let-org="rowData">
        <button pButton type="button" icon="fas fa-search" routerLink="../show/{{org.name}}"></button>
        <button pButton type="button" icon="fas fa-edit" routerLink="../edit/{{org.name}}"></button>
        <button *ngIf="showDeleteButton" pButton type="button" icon="fas fa-trash-alt" (click)="confirm(org)"></button>
      </ng-template>
    </p-column>
    <p-footer *ngIf="showSaveButton">
      <div class="ui-helper-clearfix" style="width:100%">
        <button type="button" pButton icon="fas fa-plus" style="float:left" routerLink="../edit" label="Añade una nueva organización"></button>
      </div>
    </p-footer>
  </p-dataTable>
    <!--If the user have no permissions to delete-->
  <p-dataTable *ngIf="!showEditButtons" [value]="orgs" [paginator]="true" [rows]="15" [responsive]="true" [globalFilter]="gb" [rowsPerPageOptions]="[5,10,15]" #dt>
    <p-column field="display_name" header="Nombre" [sortable]="true" [style]="{'width':'54%'}"></p-column>
    <p-column field="state" header="Estado" [sortable]="true" [style]="{'width':'20%'}"></p-column>
    <p-column field="package_count" header="Nº datos" [sortable]="true" [style]="{'width':'18%'}"></p-column>
    <p-column header="Edición"  [style]="{'width':'8%'}">
      <ng-template pTemplate="body" let-org="rowData">
        <button pButton type="button" icon="fas fa-search" routerLink="../show/{{org.name}}"></button>
      </ng-template>
    </p-column>
    <p-footer *ngIf="showSaveButton">
      <div class="ui-helper-clearfix" style="width:100%">
        <button type="button" pButton icon="fas fa-plus" style="float:left" routerLink="../edit" label="Añade una nueva organización"></button>
      </div>
    </p-footer>
  </p-dataTable>
</div>