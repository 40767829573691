<div class="container" *ngIf="errorTitle">
    <div class="alert alert-info" role="alert">
        <div class="row">
            <div class="col-xs-3">
                <i class="fas fa-exclamation-triangle fa-5x" style="margin:15px;color: #5ea2ba;"></i>
            </div>
            <div class="col-xs-9">
                <h1>{{errorTitle}}</h1>
                <h2>{{errorMessage}}</h2>
                <a class="mail" href="mailto:opendata@aragon.es">
                    <h2> <i class="far fa-envelope"></i> Contactar con el Administrador</h2>
                </a>

            </div>
        </div>
    </div>
</div>
<div class="container" *ngIf="!errorTitle">
    <h1 tabindex="{{openedMenu ? -1 : 0}}">BANCO DE DATOS</h1>
    <!-- Search -->
    <div class="row" style="margin-top: 1%">
        <div class="col-12 order-12 order-md-1 col-md-8" *ngIf="selectedSearchOption == datasetSearchOptionFreeSearch">
            <div class="form-group">
                <label for="exampleSelect1" tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Datos Sobre:</label>
                <div class="input-group">
                    <input tabindex="{{openedMenu ? -1 : 0}}" type="text" class="form-control" placeholder="Buscar por..." [(ngModel)]="textSearch" (keyup.enter)="searchDatasetsByText()"
                        #searchInput>
                    <span class="input-group-btn">
                        <button tabindex="{{openedMenu ? -1 : 0}}" class="btn btn-secondary" type="button" (click)="searchDatasetsByText()" ><i class="fas fa-search" aria-hidden="true"></i></button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 order-md-1 order-12" *ngIf="selectedSearchOption == datasetSearchOptionTopics">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Datos sobre:</label>
                <select tabindex="{{openedMenu ? -1 : 0}}" class="form-control" [(ngModel)]="selectedTopic" (change)="changeType()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let topSel of topicsSelect" [ngValue]="topSel.value">{{topSel.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 col-md-4 order-12 order-md-1" *ngIf="selectedSearchOption == datasetSearchOptionOrganizations">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Datos sobre:</label>
                <select tabindex="{{openedMenu ? -1 : 0}}" class="form-control" [(ngModel)]="selectedOrg" (change)="changeType()">
                        <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let orgs of orgsSelect" [ngValue]="orgs.value">{{orgs.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 col-md-4 order-12 order-md-4" *ngIf="selectedSearchOption == datasetSearchOptionOrganizations || selectedSearchOption == datasetSearchOptionTopics">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Del tipo:</label>
                <select tabindex="{{openedMenu ? -1 : 0}}" class="form-control" [(ngModel)]="selectedType" (change)="changeType()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let types of datasetTypes" [ngValue]="types.value">{{types.label}}</option>
                </select>
            </div>
        </div>

        <div class="col-12 order-12 order-md-1 col-md-8" *ngIf="selectedSearchOption == datasetSearchOptionTags">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}"  class="title-combo">Etiquetas:</label>
                <div tabindex="{{openedMenu ? -1 : 0}}" class="input-group">
                    <p-autoComplete [style]="{'width':'100%'}" [inputStyle]="{'padding': '0 1.5em 0.25em'}" [(ngModel)]="tags" [suggestions]="filteredTagsMultiple"
                        (completeMethod)="filterTagsMultiple($event)" [minLength]="1" placeholder="Etiquetas" value="#{tags}"
                        field="name" [multiple]="true">
                    </p-autoComplete>
                    <span class="input-group-btn">
                        <button tabindex="{{openedMenu ? -1 : 0}}" class="btn btn-secondary" type="button" (click)="changeTags()" ><i class="fas fa-search" aria-hidden="true"></i></button>
                    </span>
                </div>
            </div>
        </div>
        <div [ngClass]="selectedSearchOption == datasetSearchOptionStats && selectedGroup == undefined ? 'col-12 order-12 order-md-4 col-md-8' : 'col-12 col-md-4 order-12 order-md-1'" *ngIf="selectedSearchOption == datasetSearchOptionStats">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Del Grupo:</label>
                <select tabindex="{{openedMenu ? -1 : 0}}" class="form-control" [(ngModel)]="selectedGroup" (change)="resetSubGroupSearch();changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let group of groupSelect" [ngValue]="group.value">{{group.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 col-md-4 order-12 order-md-4" *ngIf="selectedGroup != undefined && selectedSearchOption == datasetSearchOptionStats">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Del Subgrupo:</label>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionTerritorio" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupTerritorioSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionDemografia" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupDemografiaSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionEducacion" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupEducacionSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionSalud" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupSaludSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionNivelCalidadVida" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupNivelCalidadVidaSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionAnalisisSociales" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupAnalisisSocialesSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionTrabajoSalarios" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupTrabajoSalariosSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionAgricultura" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupAgriculturaSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionServicios" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupServiciosSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionPrecios" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupPreciosSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionPib" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupPIBSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionFinancieras" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupFinancierasSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionIDITic" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupIDITicSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionMedioAmbiente" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupMedioAmbienteSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
                <select tabindex="{{openedMenu ? -1 : 0}}" *ngIf="selectedGroup == groupsOptionSectorPublico" class="form-control" [(ngModel)]="selectedSubGroup" (change)="changeStats()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let subGroup of subGroupSectorPublicoSelect" [ngValue]="subGroup.value">{{subGroup.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 order-12 order-md-1 col-md-4" *ngIf="selectedSearchOption == datasetSearchOptionHomer">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Buscar:</label>
                <div class="input-group">
                    <input tabindex="{{openedMenu ? -1 : 0}}" type="text" class="form-control" placeholder="Buscar por..." [value]="searchHomerValue" (keyup.enter)="searchDatasetsByHomer(searchHomerInput.value)"
                        #searchHomerInput>
                    <span class="input-group-btn">
                        <button tabindex="{{openedMenu ? -1 : 0}}" class="btn btn-secondary" type="button" (click)="searchDatasetsByHomer(searchHomerInput.value)"><i class="fas fa-search" aria-hidden="true"></i></button>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 order-12 order-md-4" *ngIf="selectedSearchOption == datasetSearchOptionHomer">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Idioma:</label>
                <select tabindex="{{openedMenu ? -1 : 0}}" class="form-control" [(ngModel)]="selectedLang" (change)="getDatasetsByHomer(null,null)">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let langSel of langsSelect" [ngValue]="langSel.value">{{langSel.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-12 order-md-12 order-1 col-md-4">
            <div class="form-group">
                <label tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Tipo de búsqueda</label>
                <select tabindex="{{openedMenu ? -1 : 0}}" class="form-control" [(ngModel)]="selectedSearchOption" (change)="loadDatasets()">
                    <option tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let search of searchOptions" [ngValue]="search.value">{{search.label}}</option>
                </select>
            </div>
        </div>
        <div class="col" *ngIf="selectedSearchOption === datasetSearchOptionSiu">
            <div class="form-group">
                <label for="exampleSelect1" tabindex="{{openedMenu ? -1 : 0}}" class="title-combo">Datos Sobre:</label>
                <div class="input-group">
                    <input tabindex="{{openedMenu ? -1 : 0}}" type="text" class="form-control" placeholder="Buscar por..." [(ngModel)]="textSearch" (keyup.enter)="searchDatasetsByText()"
                        #searchInput>
                    <span class="input-group-btn">
                        <button tabindex="{{openedMenu ? -1 : 0}}" class="btn btn-secondary" type="button" (click)="searchDatasetsByText()" ><i class="fas fa-search" aria-hidden="true"></i></button>
                    </span>
                </div>
              </div>
            <app-dataset-search [f1_title]="f1_title" [f2_title]="f2_title" [f1]="orgsSelect" [f2]="topicsSelect" [callback]="callbackOrgTopic.bind(this)"></app-dataset-search>
        </div>
    </div>
    <!-- End Search -->
    <div *ngIf="selectedSearchOption != datasetSearchOptionHomer">
        <p-dataTable [value]="datasets" emptyMessage="{{emptyMessage}}" (onSort)="setOrder($event)" #dt>
            <p-column field="name" header="{{numDatasets}} conjuntos de datos encontrados" sortable="custom" sortFunction="orderTable($event)">
                <ng-template pTemplate="body" let-dataset="rowData">
                    <a tabindex="{{openedMenu ? -1 : 0}}" routerLink='/{{routerLinkDataCatalogDataset}}/{{dataset.name}}' (click)="showDataset(dataset)">
                        {{dataset.title}}
                    </a>
                    <i class="fas fa-star" id="informacion_content_3_i" style="color: #5ea2ba;" *ngIf="dataset.tracking_summary && (dataset.tracking_summary.recent > 10)"></i>
                </ng-template>
            </p-column>
            <p-column field="accesos" header="Nº de accesos" [style]="{'width':'160px'}" sortable="custom" sortFunction="orderTable($event)" (window:resize)="onResize()" [hidden]="hideAccessNumberColumn">
                <ng-template pTemplate="body" let-dataset="rowData">
                    {{dataset?.tracking_summary?.total || '0'}}
                </ng-template>
            </p-column>
            <p-column field="lastUpdate" header="Última actualización" [style]="{'width':'220px'}" sortable="custom" sortFunction="orderTable($event)" (window:resize)="onResize()" [hidden]="hideLastUpdateColumn">
                <ng-template pTemplate="body" let-dataset="rowData">
                    {{dataset.metadata_modified | date: 'dd/MM/yyyy'}}
                </ng-template>
            </p-column>
        </p-dataTable>
        <nav aria-label="Pagination">
            <ul class="pagination justify-content-center">
                <li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+pageFirst+1)" aria-label="Primero">
                        <i class="fas fa-fast-backward" ></i>
                    </a>
                </li>
                <li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage)" aria-label="Anterior">
                        <i class="fas fa-step-backward"></i>
                    </a>
                </li>
                <li *ngFor="let pageShow of pagesShow" [ngClass]="pageShow == '-' ? 'page-item disabled' : (pageShow == (+actualPage+1) ? 'page-item active': 'page-item')">
                    <a tabindex="{{openedMenu ? -1 : 0}}" tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageShow)">{{pageShow}}</a>
                </li>
                <li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage+2)" aria-label="Siguiente">
                        <i class="fas fa-step-forward"></i>
                    </a>
                </li>
                <li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageLast)" aria-label="Ultimo">
                        <i class="fas fa-fast-forward"></i>
                    </a>
                </li>
            </ul>
        </nav>
        <div class="nota">
            <p>
                <i class="fas fa-star" id="informacion_content_3_i" style="color: #5ea2ba;"></i> Se ha accedido al conjunto
                de datos más de 10 veces en los últimos 14 días.
            </p>
        </div>
        <div class="destacado" style="width: 100%;">
            <h1 tabindex="{{openedMenu ? -1 : 0}}">Datos destacados</h1>
            <h3 tabindex="{{openedMenu ? -1 : 0}}" style="color: grey">Permítenos recomendarte algunos datos abiertos</h3>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="box">
                        <div class="boxTitle">
                            <h3 tabindex="{{openedMenu ? -1 : 0}}">NUEVOS DATOS</h3>
                        </div>
                        <div class="boxContent">
                            <div>
                                <div *ngFor="let newDataset of newestDatasets" class="textosNormales">
                                    <a tabindex="{{openedMenu ? -1 : 0}}" routerLink='/{{routerLinkDataCatalogDataset}}/{{newDataset.name}}' (click)="showDataset(newDataset)">
                                    {{newDataset.title}}
                                </a>
                                    <div *ngIf="newestDatasets.indexOf(newDataset) != 2" class="separadorVentana"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="box">
                        <div class="boxTitle">
                            <h3 tabindex="{{openedMenu ? -1 : 0}}">MÁS DESCARGADOS</h3>
                        </div>
                        <div class="boxContent">
                            <div>
                                <div *ngFor="let downloadedDataset of downloadedDatasets" class="textosNormales">
                                    <a tabindex="{{openedMenu ? -1 : 0}}" routerLink='/{{routerLinkDataCatalogDataset}}/{{downloadedDataset.name}}' (click)="showDataset(downloadedDataset)">
                                    {{downloadedDataset.title}}
                                </a>
                                    <div *ngIf="downloadedDatasets.indexOf(downloadedDataset) != 2" class="separadorVentana"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="box">
                        <div class="boxTitle">
                            <h3 tabindex="{{openedMenu ? -1 : 0}}">ESTADÍSTICAS</h3>
                        </div>
                        <div class="boxContent">
                            <div>
                                <h6 tabindex="{{openedMenu ? -1 : 0}}">Número de datasets</h6>
                                <h1 tabindex="{{openedMenu ? -1 : 0}}">
                                    <span tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let num of datasetCount" [ngClass]="num.label == 'slim' ? 'slimCount': 'normalCount'">{{num.value}}</span>
                                </h1>
                            </div>
                            <div class="separadorVentana"></div>
                            <div>
                                <h6 tabindex="{{openedMenu ? -1 : 0}}">Número de recursos</h6>
                                <h1>
                                    <span tabindex="{{openedMenu ? -1 : 0}}" *ngFor="let num of resourceCount" [ngClass]="num.label == 'slim' ? 'slimCount': 'normalCount'">{{num.value}}</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedSearchOption == datasetSearchOptionHomer">
        <p-dataTable [value]="datasetsHomer" emptyMessage="{{emptyMessage}}" [responsive]="true" (onSort)="setOrderHomer($event)" #dt>
            <p-column field="name" header="ENCONTRADOS {{numDatasetsHomer}} RESULTADOS EN HOMER" sortable="custom" [sortable]="true">
                <ng-template pTemplate="body" let-datasetHomer="rowData">
                    <a tabindex="{{openedMenu ? -1 : 0}}" routerLink='//{{routerLinkDataCatalogDatasetHomer}}/{{datasetHomer.package_id}}' (click)="showDatasetHomer(datasetHomer)">
                        {{datasetHomer.title}}
                    </a>
                </ng-template>
            </p-column>
            <p-column field="portal" header="PORTAL" [style]="{'width':'220px'}" sortable="custom" [sortable]="true">
                <ng-template pTemplate="body" let-datasetHomer="rowData">
                    {{datasetHomer.portal}}
                </ng-template>
            </p-column>
            <p-column field="language" header="IDIOMA" [style]="{'width':'160px'}" sortable="custom" [sortable]="true">
                <ng-template pTemplate="body" let-datasetHomer="rowData">
                    {{datasetHomer.language}}
                </ng-template>
            </p-column>
        </p-dataTable>
        <nav aria-label="Pagination">
            <ul class="pagination justify-content-center">
                <li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+pageFirst+1)" aria-label="Primero">
                        <i class="fas fa-fast-backward" ></i>
                    </a>
                </li>
                <li [ngClass]="actualPage >= 1 ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage)" aria-label="Anterior">
                        <i class="fas fa-step-backward"></i>
                    </a>
                </li>
                <li *ngFor="let pageShow of pagesShow" [ngClass]="pageShow == '-' ? 'page-item disabled' : (pageShow == (+actualPage+1) ? 'page-item active': 'page-item')">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageShow)">{{pageShow}}</a>
                </li>

                <li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(+actualPage+2)" aria-label="Siguiente">
                        <i class="fas fa-step-forward"></i>
                    </a>
                </li>
                <li [ngClass]="actualPage < (+pageLast-1) ? 'page-item': 'page-item disabled'">
                    <a tabindex="{{openedMenu ? -1 : 0}}" class="page-link" (click)="paginate(pageLast)" aria-label="Ultimo">
                        <i class="fas fa-fast-forward"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>