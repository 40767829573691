<p-growl [(value)]="organizationAdminMessages"></p-growl>
<div class="container">
  <div class="form-group row">
    <label for="name-input" class="col-4 col-form-label">NOMBRE DE ORGANIZACIÓN *</label>
    <div class="col-8">
      <input class="form-control" type="text" value="{{org.title}}" id="title" [(ngModel)]="org.title">
    </div>
  </div>
  <div class="form-group row">
    <label for="siuCode-input" class="col-4 col-form-label">SIU CODE</label>
    <div class="col-8">
      <input class="form-control" type="text" value="{{siuCode.value}}" id="siuCode" [(ngModel)]="siuCode.value">
    </div>
  </div>
  <div class="form-group row">
    <label for="webPage-input" class="col-4 col-form-label">PÁGINA WEB DE LA ORGANIZACIÓN</label>
    <div class="col-8">
      <input class="form-control" type="text" value="{{webpage.value}}" id="webPage" [(ngModel)]="webpage.value">
    </div>
  </div>
  <div class="form-group row">
    <label for="description-input" class="col-4 col-form-label">DESCRIPCIÓN DE LA ORGANIZACIÓN</label>
    <div class="col-8">
      <textarea class="form-control" rows="5" value={{org.description}} id="description" [(ngModel)]="org.description"></textarea>
    </div>
  </div>
  <div *ngIf="organizationEmpty" class="form-group row">
      <label for="inputFile" class="col-4 col-form-label">FICHERO</label>
      <div class="col-8">
        <input type="file" class="form-control" (change)="fileChange($event)" placeholder="Subir fichero" accept="image/x-png,image/gif,image/jpeg">
      </div>
  </div>
  <div class="form-group row">
    <label for="adress-input" class="col-4 col-form-label">DIRECCIÓN DE LA ORGANIZACIÓN</label>
    <div class="col-8">
      <input class="form-control" type="text" value="{{address.value}}" id="address" [(ngModel)]="address.value">
    </div>
  </div>
  <div class="form-group row">
    <label for="orgPerson-input" class="col-4 col-form-label">PERSONA RESPONSABLE DENTRO DE LA ORGANIZACIÓN</label>
    <div class="col-8">
      <input class="form-control" type="text" value="{{person.value}}" id="orgPerson" [(ngModel)]="person.value">
    </div>
  </div>
  <div class="form-group row">
    <label for="email-input" class="col-4 col-form-label">EMAIL DE CONTACTO</label>
    <div class="col-8">
      <input readonly="readonly" class="form-control" type="text" value="{{email}}" id="email" [(ngModel)]="email">
    </div>
  </div>
  <br/>
  <div *ngIf="organizationEmpty" class="ui-helper-clearfix" style="width:100%">
    <button type="button" pButton icon="fas fa-times" style="float:right; background-color: red; border-color: red;" (click)="cancel();" label="Cancelar"></button>
    <button type="button" pButton icon="far fa-save" style="float:right" (click)="saveOrganization();" label="Guardar"></button>
  </div>
  <div *ngIf="!organizationEmpty" class="ui-helper-clearfix" style="width:100%">
    <button type="button" pButton icon="fas fa-times" style="float:right; background-color: red; border-color: red;" (click)="cancel();" label="Cancelar"></button>
    <button id="updateButton" type="button" pButton icon="far fa-save" style="float:right" (click)="updateOrganization();" label="Actualizar"></button>
  </div>
</div>